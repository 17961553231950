import { gql } from '@/__generated__';

export const IS_GATE_DATA = gql(`#graphql
  query IsGateData($deviceId: ID!) {
    device(input: { id: $deviceId }) {
      id
      profile {
        id
        type: type2
      }
    }
  }
`);
