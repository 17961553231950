import { useModalStatusMessage } from '@/hooks';
import { useGlobalModal } from '@/hooks/useGlobalModal';
import { useMutation } from '@apollo/client';
import GlobalModal from '@components/GlobalModal';
import ModalActions from '@components/modal/ModalActions';
import ModalTitle from '@components/modal/ModalTitle';
import CREATE_DEVICE_USER_RELATIONS from '@features/access/graphql/mutations/createDeviceUserRelations';
import { Access } from '@features/authorization';
import GET_DEVICES_ACCESS_FOR_USER from '@features/devicesAccessInputs/graphql/queries/getDevicesAccessForUser';
import GET_USERS_ACCESS_FOR_DEVICE from '@features/devicesAccessInputs/graphql/queries/getUserAccessForDevice';
import StandardAutocompleteIdsWithFallback from '@features/standardDesign/components/autocomplete/StandardAutocompleteIds';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { AddCircle, Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  DialogContent,
  FormControlLabel,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material';
import { Fragment, useState, type FC } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

type FormValues = {
  newRelations: {
    deviceId: string;
    userId: number;
    hasAccess: boolean;
  }[];
};

type Props = {
  onClose: () => void;
} & (
  | {
      fixedDeviceId?: string;
    }
  | {
      fixedUserId?: number;
    }
);

const Component: FC<Props> = (props) => {
  const fixedDeviceId = ('fixedDeviceId' in props && props.fixedDeviceId) || undefined;
  const fixedUserId = ('fixedUserId' in props && props.fixedUserId) || undefined;

  const { _ } = useLingui();
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();

  const [selectedOrganizationIdToFilterDevices, setSelectedOrganizationIdToFilterDevices] =
    useState<number | null>(null);
  const [selectedOrganizationIdToFilterUsers, setSelectedOrganizationIdToFilterUsers] = useState<
    number | null
  >(null);

  const [addRelations] = useMutation(CREATE_DEVICE_USER_RELATIONS, {
    onCompleted: () => {
      openModalSuccessMessage(_(msg`Relaciones creadas correctamente`));
      props.onClose();
    },
    onError(error) {
      openModalErrorMessage(error.message);
    },
    refetchQueries: [GET_DEVICES_ACCESS_FOR_USER, GET_USERS_ACCESS_FOR_DEVICE],
    update(cache) {
      cache.modify({
        fields: {
          devices(_, { DELETE }) {
            return DELETE;
          },
          users(_, { DELETE }) {
            return DELETE;
          },
        },
      });
    },
  });

  const methods = useForm<FormValues>({
    defaultValues: {
      newRelations: [
        {
          deviceId: fixedDeviceId ?? '',
          userId: fixedUserId,
          hasAccess: true,
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'newRelations',
  });

  const onSubmit = methods.handleSubmit((data) => {
    addRelations({
      variables: {
        inputs: data.newRelations,
      },
    });
  });

  const onAddNewRelationButtonClick = () => {
    append({
      deviceId: fixedDeviceId,
      userId: fixedUserId,
      hasAccess: true,
    });
  };

  const onRemoveRelationButtonClick = (index: number) => () => {
    remove(index);
  };

  return (
    <form onSubmit={onSubmit}>
      <ModalTitle title={_(msg`Agregar relaciones dispositivo-usuario`)} onClose={props.onClose} />
      <DialogContent dividers>
        <Typography variant="h6" mb={1}>
          <Trans>Filtros</Trans>
        </Typography>
        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2} mb={2}>
          {!fixedDeviceId && (
            <StandardAutocompleteIdsWithFallback
              sx={{ flex: 2 }}
              size="small"
              label={_(msg`Selecciona una organización para filtrar dispositivos`)}
              dataType="organizations"
              value={selectedOrganizationIdToFilterDevices}
              onChange={(_, value) => {
                setSelectedOrganizationIdToFilterDevices(value);
              }}
            />
          )}
          {!fixedUserId && (
            <StandardAutocompleteIdsWithFallback
              sx={{ flex: 2 }}
              size="small"
              label={_(msg`Selecciona una organización para filtrar usuarios`)}
              dataType="organizations"
              value={selectedOrganizationIdToFilterUsers}
              onChange={(_, value) => {
                setSelectedOrganizationIdToFilterUsers(value);
              }}
            />
          )}
        </Box>
        <Typography variant="h6" mb={1}>
          <Trans>Indica las relaciones que deseas agregar</Trans>
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr auto auto"
          columnGap={2}
          rowGap={1.5}
          alignItems="center"
          alignContent="start"
          height="40dvh"
          border={1}
          borderColor="divider"
          p={1}
          borderRadius={1}
          sx={{ overflowY: 'scroll' }}
        >
          {fields.map((field, index, { length }) => {
            return (
              <Fragment key={field.id}>
                <Controller
                  control={methods.control}
                  name={`newRelations.${index}.deviceId`}
                  render={({ field: { ref, onChange, ...field }, fieldState: { error } }) => (
                    <StandardAutocompleteIdsWithFallback
                      {...field}
                      onChange={(_, value) => onChange(value)}
                      size="small"
                      disabled={fixedDeviceId != null}
                      dataType="devices"
                      error={Boolean(error)}
                      helperText={error?.message}
                      queryOptions={{
                        devices: {
                          userIdWithoutRelation: methods.watch(`newRelations.${index}.userId`),
                          organizations:
                            selectedOrganizationIdToFilterDevices != null
                              ? [selectedOrganizationIdToFilterDevices]
                              : undefined,
                        },
                      }}
                    />
                  )}
                />
                <Controller
                  control={methods.control}
                  name={`newRelations.${index}.userId`}
                  render={({ field: { ref, onChange, ...field }, fieldState: { error } }) => (
                    <StandardAutocompleteIdsWithFallback
                      {...field}
                      onChange={(_, value) => onChange(value)}
                      size="small"
                      disabled={fixedUserId != null}
                      dataType="users"
                      error={Boolean(error)}
                      helperText={error?.message}
                      queryOptions={{
                        users: {
                          deviceIdWithoutRelation: methods.watch(`newRelations.${index}.deviceId`),
                          organizationIds:
                            selectedOrganizationIdToFilterUsers != null
                              ? [selectedOrganizationIdToFilterUsers]
                              : undefined,
                        },
                      }}
                    />
                  )}
                />
                <Controller
                  control={methods.control}
                  name={`newRelations.${index}.hasAccess`}
                  render={({ field: { value, ...field } }) => (
                    <FormControlLabel
                      {...field}
                      checked={value}
                      label={<Trans>Acceso</Trans>}
                      control={<Checkbox />}
                    />
                  )}
                />
                <IconButton onClick={onRemoveRelationButtonClick(index)} disabled={length === 1}>
                  <Delete fontSize="small" />
                </IconButton>
              </Fragment>
            );
          })}
        </Box>
        <Button sx={{ mt: 1 }} startIcon={<AddCircle />} onClick={onAddNewRelationButtonClick}>
          <Trans>Agregar nueva relación</Trans>
        </Button>
      </DialogContent>
      <ModalActions onSubmit="submit" onClose={props.onClose} />
    </form>
  );
};

const Fallback: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { _ } = useLingui();
  return (
    <>
      <ModalTitle title={_(msg`Agregar relaciones dispositivo-usuario`)} onClose={onClose} />
      <DialogContent dividers>
        <Skeleton height={100} variant="rounded" />
      </DialogContent>
      <ModalActions onClose={onClose} />
    </>
  );
};

const AddDeviceUserRelationsModal: FC = () => {
  const { globalModalProps, state, onClose } = useGlobalModal('addDeviceUserRelationsModal');

  return (
    <GlobalModal
      {...globalModalProps}
      dialogProps={{ maxWidth: 'md' }}
      authorization={{
        access: Access.CreateDeviceUserAccess,
        onClose,
      }}
      suspenseFallback={<Fallback onClose={onClose} />}
    >
      {state.open && (
        <>
          {'fixedUserId' in state ? (
            <Component fixedUserId={state.fixedUserId} onClose={onClose} />
          ) : 'fixedDeviceId' in state ? (
            <Component fixedDeviceId={state.fixedDeviceId} onClose={onClose} />
          ) : (
            <Component onClose={onClose} />
          )}
        </>
      )}
    </GlobalModal>
  );
};

export default AddDeviceUserRelationsModal;
