import { useCallback, useState } from 'react';

export interface UsePaginationProps {
  totalCount: number;
  /**
   * @default 1
   */
  initialPage?: number;
  /**
   * @default 10
   */
  initialRowsPerPage?: number;
  /**
   * @default false
   */
  disableRowsPerPageInput?: boolean;
}

export const usePagination = ({
  totalCount,
  initialPage = 1,
  initialRowsPerPage = 10,
  disableRowsPerPageInput = false,
}: UsePaginationProps) => {
  const [pagination, setPagination] = useState({
    page: initialPage,
    rowsPerPage: initialRowsPerPage,
  });

  const reset = useCallback(() => {
    setPagination({
      page: initialPage,
      rowsPerPage: initialRowsPerPage,
    });
  }, [setPagination, initialPage, initialRowsPerPage]);

  return {
    limit: pagination.rowsPerPage,
    offset: (pagination.page - 1) * pagination.rowsPerPage,
    reset,
    StandardListPaginationProps: {
      totalItems: totalCount,
      page: pagination.page,
      rowsPerPage: pagination.rowsPerPage,
      onPageChange: (page: number) => setPagination((prev) => ({ ...prev, page })),
      onRowsPerPageChange: disableRowsPerPageInput
        ? undefined
        : (rowsPerPage: number) => setPagination((prev) => ({ ...prev, rowsPerPage })),
    },
  };
};
