import { IS_GATE_DATA } from '@/graphql/querys/isGateData';
import { getIsGate } from '@/utils/devices/isGate';
import { useSuspenseQuery } from '@apollo/client';

export const useIsGate = (deviceId: string) => {
  const { data } = useSuspenseQuery(IS_GATE_DATA, {
    variables: { deviceId },
  });
  return getIsGate(data.device.profile.type);
};
