import ApolloErrorBoundary from '@components/ApolloErrorBoundary';
import { Suspense, type FC } from 'react';
import { TabFallback, type TabProps } from '../common';
import { FormComponent } from './FormComponent';

export const BasicConfigurationTab: FC<TabProps> = (props) => {
  return (
    <ApolloErrorBoundary variant="modal">
      <Suspense fallback={<TabFallback onClose={props.onClose} />}>
        <FormComponent {...props} />
      </Suspense>
    </ApolloErrorBoundary>
  );
};
