import { useFormatters } from '@/hooks/useFormatters';
import { usePagination } from '@/hooks/usePagination';
import { useSuspenseQuery } from '@apollo/client';
import UserTooltip from '@components/tooltips/UserTooltip';
import StandardListPagination from '@features/standardDesign/components/listView/StandardListPagination';
import StandardTable, {
  StandardTableHeaderCell,
  StandardTableRow,
} from '@features/standardDesign/components/StandardTable';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, Checkbox, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, type FC } from 'react';
import { GET_USERS_FOR_DEVICE } from './getUsersForDevice';
import { FormValues } from './updateSchema';

type Value = FormValues['input'];

interface Props {
  deviceId: string;
  value: Value;
  onChange: (value: Value) => void;
}

const keys = ['notifyEmail', 'notifySms', 'notifyWhatsapp', 'notifyPush'] as const;

type NotificationKey = (typeof keys)[number];

export const TableForm: FC<Props> = ({ deviceId, value, onChange }) => {
  const { formatPhoneNumber } = useFormatters();
  const { _ } = useLingui();
  const { data } = useSuspenseQuery(GET_USERS_FOR_DEVICE, {
    variables: {
      deviceId,
    },
  });

  const {
    limit,
    offset,
    reset: resetPagination,
    StandardListPaginationProps,
  } = usePagination({
    totalCount: data.device.users.length,
    initialRowsPerPage: 5,
    disableRowsPerPageInput: true,
  });

  const relations = useMemo(() => {
    // sort
    const sortedRelations = Array.from(data.device.users).sort((a, b) =>
      a.user.fullName.localeCompare(b.user.fullName),
    );
    // paginate
    return sortedRelations.slice(offset, offset + limit);
  }, [data.device.users, limit, offset]);

  useEffect(resetPagination, [data, resetPagination]);

  const isChecked = useCallback(
    (
      userId: number,
      key: NotificationKey,
    ): {
      edited: boolean;
      checked: boolean;
    } => {
      const relation = data.device.users.find((r) => r.user.id === userId);
      const defaultValue =
        relation?.alertNotificationConfiguration ??
        (Object.fromEntries(keys.map((key) => [key, false])) as Record<NotificationKey, false>);

      const editedValue = value.find((v) => v.userId === userId && v.deviceId === deviceId);
      if (editedValue)
        return {
          edited: defaultValue[key] !== editedValue[key],
          checked: editedValue[key],
        };
      else
        return {
          edited: false,
          checked: defaultValue[key],
        };
    },
    [data.device.users, value, deviceId],
  );

  const isAllChecked = useCallback(
    (key: NotificationKey) => {
      const isCheckedList = data.device.users.map(({ user }) => isChecked(user.id, key));
      return {
        checked: isCheckedList.every(({ checked }) => checked),
        edited: isCheckedList.some(({ edited }) => edited),
      };
    },
    [data.device.users, isChecked],
  );

  const getResultAfterChangeCell = useCallback(
    (userId: number, key: NotificationKey, checked: boolean, value: Value) => {
      const relation = data.device.users.find((r) => r.user.id === userId);
      const defaultValue =
        relation?.alertNotificationConfiguration ??
        (Object.fromEntries(keys.map((key) => [key, false])) as Record<NotificationKey, false>);

      const currentValue = value.find((v) => v.userId === userId && v.deviceId === deviceId);
      const result = value.filter((v) => !(v.userId === userId && v.deviceId === deviceId));

      const newValue = currentValue ?? {
        ...defaultValue,
        userId,
        deviceId,
      };

      newValue[key] = checked;

      if (keys.some((key) => newValue[key] !== defaultValue[key])) {
        result.push(newValue);
      }

      return result;
    },
    [data.device.users, deviceId],
  );

  const headers: StandardTableHeaderCell[] = useMemo(() => {
    const cells = (
      [
        ['notifyEmail', <Trans>Correo</Trans>],
        ['notifySms', <Trans>SMS</Trans>],
        ['notifyWhatsapp', <Trans>Whatsapp</Trans>],
        ['notifyPush', <Trans>Amaru Lite</Trans>],
      ] as const
    ).map(([key, label]) => {
      const { checked, edited } = isAllChecked(key);
      return {
        id: key,
        value: (
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={checked}
              onChange={(_, checked) => {
                let result = [...value];
                for (const relation of data.device.users) {
                  result = getResultAfterChangeCell(relation.user.id, key, checked, result);
                }
                onChange(result);
              }}
              color={edited ? 'primary' : 'info'}
              sx={{
                color: edited ? ({ palette }) => palette.primary.dark : undefined,
              }}
            />
            <Box width={80} sx={{ textWrap: 'nowrap' }}>
              {label}
            </Box>
          </Box>
        ),
      };
    });

    return [<Trans>Información del usuario</Trans>, ...cells];
  }, [isAllChecked, data.device.users, getResultAfterChangeCell, onChange, value]);

  const rows: StandardTableRow[] = useMemo(() => {
    return relations.map(({ id, user }): StandardTableRow => {
      const cells = keys.map((key) => {
        const { checked, edited } = isChecked(user.id, key);
        return {
          id: key,
          cellProps: {
            width: 100,
          },
          value: (
            <Box display="flex" alignItems="center">
              <Checkbox
                checked={checked}
                onChange={(_, checked) => {
                  const result = getResultAfterChangeCell(user.id, key, checked, value);
                  onChange(result);
                }}
                color={edited ? 'primary' : 'info'}
                sx={{
                  color: edited ? ({ palette }) => palette.primary.dark : undefined,
                }}
              />
            </Box>
          ),
        };
      });

      return {
        id,
        cells: [
          {
            id: 'info',
            cellProps: {
              width: 300,
            },
            value: (
              <Box>
                <UserTooltip userId={user.id}>
                  <Box>
                    <Typography fontSize="inherit">{user.fullName}</Typography>
                    <Typography fontSize="small">{user.email}</Typography>
                    <Typography fontSize="small">
                      {user.phone ? formatPhoneNumber(user.phone) : _(msg`Sin número de teléfono`)}
                    </Typography>
                  </Box>
                </UserTooltip>
              </Box>
            ),
          },
          ...cells,
        ],
      };
    });
  }, [relations, isChecked, onChange, value, getResultAfterChangeCell]);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box border={1} borderColor="divider" borderRadius={1}>
        <StandardTable
          headers={headers}
          rows={rows}
          slotProps={{
            tableContainer: {
              component: 'div',
              sx: { flexGrow: 1 },
            },
          }}
        />
      </Box>
      <StandardListPagination {...StandardListPaginationProps} mt={2} />
    </Box>
  );
};
