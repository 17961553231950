import { WriteExecutionLogSeverity } from '@/__generated__/graphql';
import { type I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Info, ReportProblemRounded, SvgIconComponent, Cancel } from '@mui/icons-material';
import { indigo, orange, red, yellow } from '@mui/material/colors';

export type WriteExecutionLogSeverityAttributes = {
  /**
   * The status with the lowest precedence value will
   * be considered the most important
   */
  precedence: number;
  severity: WriteExecutionLogSeverity;
  label: string;
  color: string;
  contrastColor: string;
  Icon: SvgIconComponent;
};

const COLOR_SHADE = 400;
const CONTRAST_COLOR_SHADE = 50;

export const getWriteExecutionLogSeverityAttributesMap = (
  i18n: I18n,
): Record<WriteExecutionLogSeverity, WriteExecutionLogSeverityAttributes> => ({
  [WriteExecutionLogSeverity.Critical]: {
    precedence: 0,
    severity: WriteExecutionLogSeverity.Critical,
    label: t(i18n)`Crítico`,
    color: red[COLOR_SHADE],
    contrastColor: indigo[CONTRAST_COLOR_SHADE],
    Icon: Cancel,
  },
  [WriteExecutionLogSeverity.Error]: {
    precedence: 1,
    severity: WriteExecutionLogSeverity.Error,
    label: t(i18n)`Error`,
    color: orange[COLOR_SHADE],
    contrastColor: red[CONTRAST_COLOR_SHADE],
    Icon: ReportProblemRounded,
  },
  [WriteExecutionLogSeverity.Warning]: {
    precedence: 2,
    severity: WriteExecutionLogSeverity.Warning,
    label: t(i18n)`Advertencia`,
    color: yellow[COLOR_SHADE],
    contrastColor: orange[CONTRAST_COLOR_SHADE],
    Icon: ReportProblemRounded,
  },
  [WriteExecutionLogSeverity.Info]: {
    precedence: 3,
    severity: WriteExecutionLogSeverity.Info,
    label: t(i18n)`Info`,
    color: indigo[COLOR_SHADE],
    contrastColor: indigo[CONTRAST_COLOR_SHADE],
    Icon: Info,
  },
});

/**
 * Gets the write execution log severity attributes.
 *
 * @param severity - The write execution log severity.
 * @returns The WriteExecutionLogSeverityAttributes for the given severity.
 * @throws Will throw an error if the severity is not found in the getWriteExecutionLogSeverityAttributesMap.
 */
export const getWriteExecutionLogSeverityAttributes = (
  severity: WriteExecutionLogSeverity,
  i18n: I18n,
) => {
  const attributesMap = getWriteExecutionLogSeverityAttributesMap(i18n);
  if (!(severity in attributesMap)) {
    throw new Error(`Severity ${severity} not found in getWriteExecutionLogSeverityAttributesMap.`);
  }
  return attributesMap[severity];
};

/**
 * Gets the severity with the lowest precedence from a list of objects.
 * If the list is empty, it defaults to "Info" severity.
 *
 * @param items - List of objects containing a severity property.
 * @param i18n - The i18n instance for localization.
 * @returns The WriteExecutionLogSeverityAttributes with the lowest precedence.
 */
export const getLowestPrecedenceSeverity = (
  items: { severity: WriteExecutionLogSeverity }[],
  i18n: I18n,
): WriteExecutionLogSeverityAttributes => {
  if (items.length === 0)
    return getWriteExecutionLogSeverityAttributes(WriteExecutionLogSeverity.Info, i18n);

  const severityAttributesMap = getWriteExecutionLogSeverityAttributesMap(i18n);
  return items
    .map((item) => severityAttributesMap[item.severity])
    .reduce((lowest, current) => (current.precedence < lowest.precedence ? current : lowest));
};
