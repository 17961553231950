import useConfirmationModal from '@/hooks/useConfirmationModal';
import ConfirmationModal from '@components/modal/ConfirmationModal';
import { AuthorizationWrapper, AuthorizationWrapperProps } from '@features/authorization';
import { Dialog, DialogProps, Skeleton } from '@mui/material';
import { ComponentType, memo, PropsWithChildren, ReactNode, Suspense, type FC } from 'react';
import { FallbackProps } from 'react-error-boundary';
import ApolloErrorBoundary from './ApolloErrorBoundary';
import ModalActions, { type ModalActionsProps } from './modal/ModalActions';
import ModalTitle, { type ModalTitleProps } from './modal/ModalTitle';
import ModalContent, { ModalContentProps } from './modal/ModalContent';

export interface GlobalModalProps extends PropsWithChildren {
  onClose?: () => void;
  onExited?: () => void;
  open: boolean;
  dialogProps?: Partial<DialogProps>;
  confirmationModal?: ReturnType<typeof useConfirmationModal> | null;
  modalTitleProps?: ModalTitleProps | null;
  modalActionProps?: ModalActionsProps | null;
  authorization?: AuthorizationWrapperProps | null;
  suspenseFallback?: ReactNode | null;
  errorFallback?: ComponentType<FallbackProps> | null;
  formProps?: React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>;
  modalContentProps?: ModalContentProps;
}

const FormWrapper: FC<PropsWithChildren<Pick<GlobalModalProps, 'formProps'>>> = memo(
  ({ children, formProps }) => (formProps ? <form {...formProps}>{children}</form> : children),
);

const ModalContentWrapper: FC<PropsWithChildren<Pick<GlobalModalProps, 'modalContentProps'>>> =
  memo(({ children, modalContentProps }) =>
    modalContentProps ? <ModalContent {...modalContentProps}>{children}</ModalContent> : children,
  );

const GlobalModal: FC<GlobalModalProps> = ({
  confirmationModal,
  onExited,
  onClose,
  open,
  children,
  dialogProps,
  modalTitleProps,
  modalActionProps,
  authorization,
  suspenseFallback,
  errorFallback,
  formProps,
  modalContentProps,
}) => {
  return (
    <Dialog
      TransitionProps={{ onExited }}
      onClose={onClose}
      open={open}
      maxWidth="md"
      fullWidth
      {...dialogProps}
    >
      <FormWrapper formProps={formProps}>
        {modalTitleProps != null && <ModalTitle {...modalTitleProps} />}
        <ModalContentWrapper modalContentProps={modalContentProps}>
          <ApolloErrorBoundary variant="modal" fallback={errorFallback}>
            <Suspense fallback={suspenseFallback ?? <DefaultSuspenseFallback />}>
              <AuthorizationWrapper {...{ onClose, ...authorization }}>
                {confirmationModal && (
                  <ConfirmationModal {...confirmationModal.confirmationModalProps} />
                )}
                {children}
              </AuthorizationWrapper>
            </Suspense>
          </ApolloErrorBoundary>
        </ModalContentWrapper>
        {modalActionProps != null && <ModalActions {...modalActionProps} />}
      </FormWrapper>
    </Dialog>
  );
};

const DefaultSuspenseFallback = () => {
  return <Skeleton variant="rounded" width="100%" height={400} />;
};

export default GlobalModal;
