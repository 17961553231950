import {
  Autocomplete,
  TextField,
  TextFieldProps,
  TextFieldVariants,
  type AutocompleteProps,
} from '@mui/material';
import {
  type FieldPath,
  type FieldValues,
  type ControllerProps,
  Controller,
} from 'react-hook-form';

interface AutocompleteControllerProps<
  // autocomplete generics
  TValue,
  TMultiple extends boolean | undefined,
  TDisableClearable extends boolean | undefined,
  TFreeSolo extends boolean | undefined,
  TChipComponent extends React.ElementType,
  // textField generics
  TTextFieldVariant extends TextFieldVariants,
  // controller generics
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, 'render'> {
  options: AutocompleteProps<
    TValue,
    TMultiple,
    TDisableClearable,
    TFreeSolo,
    TChipComponent
  >['options'];
  label?: TextFieldProps<TTextFieldVariant>['label'];
  slotProps?: {
    autocomplete?: Partial<
      AutocompleteProps<TValue, TMultiple, TDisableClearable, TFreeSolo, TChipComponent>
    >;
    textField?: Partial<TextFieldProps<TTextFieldVariant>>;
  };
}

export const AutocompleteController = <
  TValue,
  TMultiple extends boolean | undefined,
  TDisableClearable extends boolean | undefined,
  TFreeSolo extends boolean | undefined,
  TChipComponent extends React.ElementType = 'div',
  TTextFieldVariant extends TextFieldVariants = TextFieldVariants,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: AutocompleteControllerProps<
    TValue,
    TMultiple,
    TDisableClearable,
    TFreeSolo,
    TChipComponent,
    TTextFieldVariant,
    TFieldValues,
    TName
  >,
) => {
  const { label, options, slotProps, ...controllerProps } = props;
  return (
    <Controller
      {...controllerProps}
      render={({ field, fieldState }) => (
        <Autocomplete
          value={field.value}
          onChange={(_, value) => field.onChange(value)}
          options={options}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={Boolean(fieldState.error)}
              helperText={fieldState.error?.message}
              {...slotProps?.textField}
            />
          )}
          {...slotProps?.autocomplete}
        />
      )}
    />
  );
};
