import ApolloErrorBoundary from '@components/ApolloErrorBoundary';
import { Suspense, useEffect, type FC } from 'react';
import { Controller } from 'react-hook-form';
import { TabContent, TabFallback, type TabProps } from '../common';
import { TableForm } from './TableForm';
import { useUpdateForm } from './useUpdateForm';

export const NotificationsTab: FC<TabProps> = ({ deviceId, onClose, setIsDirty }) => {
  const { methods, onSubmit } = useUpdateForm();

  useEffect(() => {
    setIsDirty(methods.formState.isDirty);
  }, [methods.formState.isDirty, setIsDirty]);

  return (
    <ApolloErrorBoundary variant="modal">
      <Suspense fallback={<TabFallback onClose={onClose} />}>
        <form onSubmit={onSubmit}>
          <TabContent
            slotProps={{
              modalActions: {
                onClose,
                onSubmit: 'submit',
                dirtyForm: methods.formState.isDirty,
                submitLoading: methods.formState.isSubmitting,
                onResetForm: () => methods.reset(),
              },
            }}
          >
            <Controller
              control={methods.control}
              name="input"
              render={({ field }) => {
                return (
                  <TableForm deviceId={deviceId} value={field.value} onChange={field.onChange} />
                );
              }}
            />
          </TabContent>
        </form>
      </Suspense>
    </ApolloErrorBoundary>
  );
};
