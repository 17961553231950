import {
  DebtsAlertBanner,
  DebtsAlertModal,
  DeviceProfileModal,
  DevicesAnalysisViewModal,
  UserPreferencesModal,
  UserProfileModal,
  UserProfileUpdateModal,
} from '@/features/';
import useAuth from '@/hooks/useAuth';
import { useI18n } from '@/hooks/useI18n';
import { DeviceUserAccessGlobalModals } from '@features/access';
import { AlertAdvancedCreationModal } from '@features/alertAdvancedCreation';
import AnnouncementsViewModal from '@features/announcements/components/AnnouncementsViewModal';
import { useAuthorizationRefetch } from '@features/authorization';
import UpdateDeviceAlertsModal from '@features/createDeviceAlerts/components/CreateDeviceAlerts';
import SaveHardwareConfigurationModal from '@features/createDeviceConfiguration/components/SaveHardwareConfiguration';
import { DeviceConfigurationGlobalModals } from '@features/deviceConfigurationModal';
import DeviceDataConfigurationModal from '@features/deviceDataConfigurationModal';
import { DeviceObservationsModals } from '@features/deviceObservations';
import DGAModals from '@features/dga/components/modals/DGAModals';
import { useGatesMovementGlobalActions } from '@features/gateAdministration/hooks/useGatesMovementGlobalActions';
import { GroupedIssuesModal } from '@features/integrations';
import { SmaSuccessTableModal, SmaUpsertModal } from '@features/sma';
import SMAReportsTableModal from '@features/sma/components/TableModal';
import { AccountGeneral } from '@features/userProfile/components/old/AccountGeneral';
import { SaveUserModalGlobal } from '@features/userSaveModal';
import DeleteUserModal from '@features/users/components/modals/DeleteUserModal';
import ExtendTemporaryUserModal from '@features/users/components/modals/ExtendTemporaryUserModal';
import ExtendTemporaryUserTokenModal from '@features/users/components/modals/ExtendTemporaryUserTokenModal';
import SimulateUserModal from '@features/users/components/modals/SimulateUserModal';
import PicturePreview from './PicturePreview';
import GlobalSnackbar from './Snackbar';
import CreateMeasurementAdapterModal from './optionsMenu/dialogs/CreateMeasurementAdapterModal';
import ManualDataDetailsAdapterModal from './optionsMenu/dialogs/ManualDataDetailsAdapterModal';
import { DeviceAlertsModal } from '@features/deviceAlertsModal';
import { DevicesGraveyard } from '@features/devicesGraveyard';
import { DisableAlertsModal } from '@features/deviceAlertsModal/components/disableAlertsModal';

const LoggedInGlobalComponents = () => {
  useGatesMovementGlobalActions();
  useI18n();
  useAuthorizationRefetch();

  return (
    <>
      {/* Place for global components that needs auth */}
      <AccountGeneral />
      <UserPreferencesModal />
      <UserProfileModal />
      <UserProfileUpdateModal />
      <SimulateUserModal />
      <DeleteUserModal />
      <SaveUserModalGlobal />
      <PicturePreview />
      <DebtsAlertModal />
      <DebtsAlertBanner />
      <AnnouncementsViewModal />
      <DevicesAnalysisViewModal />
      <DeviceProfileModal />
      <DeviceConfigurationGlobalModals />
      <DeviceDataConfigurationModal />
      <DGAModals />
      <UpdateDeviceAlertsModal />
      <DeviceObservationsModals />
      <CreateMeasurementAdapterModal />
      <ManualDataDetailsAdapterModal />
      <SmaUpsertModal />
      <SmaSuccessTableModal />
      <SMAReportsTableModal />
      <AlertAdvancedCreationModal />
      <ExtendTemporaryUserModal />
      <ExtendTemporaryUserTokenModal />
      <SaveHardwareConfigurationModal />
      <DeviceUserAccessGlobalModals />
      <DevicesGraveyard />
      <GroupedIssuesModal />
      <DeviceAlertsModal />
      <DisableAlertsModal />
    </>
  );
};

const GlobalComponents = () => {
  const { isAuthenticated } = useAuth();

  return (
    <>
      {/* No auth needed global components */}
      <GlobalSnackbar />
      {isAuthenticated && <LoggedInGlobalComponents />}
    </>
  );
};

export default GlobalComponents;
