import { type DeviceAuthorization } from '../graphql/queries/getDeviceAuthorization';
import { Access } from './accessEnum';

export const validateDeviceAuthorization = (
  auth: DeviceAuthorization,
  access: Access,
  deviceId: string,
): boolean => {
  const isValid = (access: Access) => {
    return validateDeviceAuthorization(auth, access, deviceId);
  };

  if (access === Access.DeviceConfigurationModal) {
    return [
      Access.DeviceConfiguration_GeneralTab,
      Access.DeviceConfiguration_StateTab,
      Access.DeviceConfiguration_AccessTab,
      Access.CloneDevice,
      Access.DeleteDevice,
    ].some(isValid);
  }

  if (access === Access.DeviceConfiguration_GeneralTab) {
    return [Access.UpdateDeviceAlias, Access.UpdateDeviceBasicConfiguration].some(isValid);
  }

  if (access === Access.DeviceConfiguration_GateTab) {
    return auth.updateGateConfiguration;
  }

  if (access === Access.DeviceConfiguration_StateTab) {
    return [Access.UpdateDeviceOperationalState].some(isValid);
  }

  if (access === Access.DeviceConfiguration_AccessTab) {
    return [Access.UpdateDevice_DeviceUserAccess].some(isValid);
  }

  if (access === Access.UpdateDeviceAlias) {
    return auth.updateAlias;
  }

  if (access === Access.UpdateDeviceBasicConfiguration) {
    return auth.update;
  }

  if (access === Access.UpdateDeviceOperationalState) {
    return [
      Access.UpdateDeviceOperationalState_ProductionToInitialization,
      Access.UpdateDeviceOperationalState_ProductionToOutOfService,
      Access.UpdateDeviceOperationalState_InitializationToActive,
      Access.UpdateDeviceOperationalState_InitializationToOutOfService,
      Access.UpdateDeviceOperationalState_ActiveToMaintenance,
      Access.UpdateDeviceOperationalState_ActiveToOutOfService,
      Access.UpdateDeviceOperationalState_MaintenanceToActive,
      Access.UpdateDeviceOperationalState_MaintenanceToOutOfService,
    ].some(isValid);
  }

  if (access === Access.UpdateDeviceOperationalState_ProductionToInitialization) {
    return auth.updateOperationalStateProductionToInitialization;
  }

  if (access === Access.UpdateDeviceOperationalState_ProductionToOutOfService) {
    return auth.updateOperationalStateProductionToOutOfService;
  }

  if (access === Access.UpdateDeviceOperationalState_InitializationToActive) {
    return auth.updateOperationalStateInitializationToActive;
  }

  if (access === Access.UpdateDeviceOperationalState_InitializationToOutOfService) {
    return auth.updateOperationalStateInitializationToOutOfService;
  }

  if (access === Access.UpdateDeviceOperationalState_ActiveToMaintenance) {
    return auth.updateOperationalStateActiveToMaintenance;
  }

  if (access === Access.UpdateDeviceOperationalState_ActiveToOutOfService) {
    return auth.updateOperationalStateActiveToOutOfService;
  }

  if (access === Access.UpdateDeviceOperationalState_MaintenanceToActive) {
    return auth.updateOperationalStateMaintenanceToActive;
  }

  if (access === Access.UpdateDeviceOperationalState_MaintenanceToOutOfService) {
    return auth.updateOperationalStateMaintenanceToOutOfService;
  }

  if (access === Access.CloneDevice) {
    return auth.clone;
  }

  if (access === Access.DeleteDevice) {
    return auth.delete;
  }

  if (access === Access.UpdateDevice_DeviceUserAccess) {
    return auth.updateUserAccess;
  }

  if (access === Access.DeviceAlertsModal) {
    return [
      Access.UpdateDeviceAlertNotificationsConfiguration,
      Access.UpdateDeviceAlertsConfiguration,
      Access.UpdateDeviceAlertsBasicConfiguration,
      Access.DisableDeviceAlertsConfiguration,
    ].some(isValid);
  }

  if (access === Access.UpdateDeviceAlertNotificationsConfiguration) {
    return auth.updateUserAlertNotifications;
  }

  if (
    access === Access.UpdateDeviceAlertsBasicConfiguration ||
    access === Access.DisableDeviceAlertsConfiguration
  ) {
    return auth.updateAlerts;
  }

  if (
    access === Access.UpdateDeviceAlertsConfiguration ||
    access === Access.ShowRawDeviceAlertsData
  ) {
    return auth.updateAdvancedAlerts;
  }

  if (access === Access.DeviceDataConfigurationModal && auth.viewDeviceConfiguration) {
    return true;
  }

  if (access === Access.DeviceDataConfiguration_CalibrationTab && auth.viewCalibration) {
    return true;
  }
  if (access === Access.DeviceDataConfiguration_FlowLimitTab && auth.viewFlowLimit) {
    return true;
  }
  if (access === Access.DeviceDataConfiguration_FiltersTab && auth.viewFilter) {
    return true;
  }
  if (access === Access.DeviceDataConfiguration_RatingCurvesTab && auth.viewRatingCurve) {
    return true;
  }
  if (access === Access.DeviceDataConfiguration_MathModelingTab && auth.viewMathModeling) {
    return true;
  }
  if (access === Access.DeviceDataConfiguration_RadarTab && auth.viewRadarParameter) {
    return true;
  }
  if (access === Access.UpdateRatingCurve && auth.updateRatingCurve) {
    return true;
  }

  if (access === Access.UpdateDeviceStatus && auth.updateState) {
    return true;
  }

  return false;
};
