import {
  BasicAlertConfigurations,
  DeviceAlertMetricName,
  MetricUnit,
} from '@/__generated__/graphql';
import { useModalStatusMessage } from '@/hooks';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import { GET_BASIC_ALERT_CONFIGURATION } from '@features/deviceAlertsModal/graphql/queries/getBasicAlertConfiguration';
import { zodResolver } from '@hookform/resolvers/zod';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { type DefaultValues, useForm } from 'react-hook-form';
import { UPDATE_BASIC_ALERT_CONFIGURATION } from './updateMutation';
import { type FormValues, getUpdateBasicAlertConfigurationInputSchema } from './updateSchema';
import { useEffect } from 'react';

const getDefaultValues = (deviceId: string, data: Partial<BasicAlertConfigurations>) => {
  const key = data.lastFlow ? 'lastFlow' : data.lastLevel ? 'lastLevel' : null;
  const activeConfiguration: DefaultValues<FormValues> = {
    deviceId,
    min: key ? data[key]?.min ?? undefined : undefined,
    max: key ? data[key]?.max ?? undefined : undefined,
    overflow: key ? data[key]?.overflow ?? undefined : undefined,
    alertType: key === 'lastLevel' ? DeviceAlertMetricName.Level : DeviceAlertMetricName.Flow,
  };
  return activeConfiguration;
};

export const useUpdateForm = (deviceId: string) => {
  const { _, i18n } = useLingui();
  const { openModalSuccessMessage, openModalErrorMessage } = useModalStatusMessage();
  const {
    data: {
      device: {
        alerts: { basicConfigurations },
      },
    },
  } = useSuspenseQuery(GET_BASIC_ALERT_CONFIGURATION, {
    variables: { deviceId },
  });

  const methods = useForm<FormValues>({
    defaultValues: getDefaultValues(deviceId, basicConfigurations),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(getUpdateBasicAlertConfigurationInputSchema(i18n)),
  });

  const [mutate] = useMutation(UPDATE_BASIC_ALERT_CONFIGURATION, {
    onCompleted({ updateBasicAlertConfiguration: { basicConfigurations } }) {
      openModalSuccessMessage(_(msg`Configuración de alertas actualizada correctamente`));
      methods.reset(getDefaultValues(deviceId, basicConfigurations));
    },
    onError(error) {
      openModalErrorMessage(
        _(msg`Error al actualizar la configuración de alertas: ${error.message}`),
      );
    },
  });

  useEffect(() => {
    methods.reset(getDefaultValues(deviceId, basicConfigurations));
  }, [deviceId, basicConfigurations, methods]);

  const onSubmit = methods.handleSubmit((data) => {
    const unit =
      data.alertType === DeviceAlertMetricName.Flow
        ? MetricUnit.LiterPerSecond
        : MetricUnit.Centimeter;

    mutate({
      variables: {
        input: {
          ...data,
          unit,
        },
      },
    });
  });

  return {
    methods,
    onSubmit,
  };
};
