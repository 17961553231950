import IS_REGIS_DATA from '@/graphql/querys/isRegisData';
import { getIsRegis } from '@/utils/devices/isRegis';
import { useSuspenseQuery } from '@apollo/client';

const useIsRegis = (deviceId: string) => {
  const { data } = useSuspenseQuery(IS_REGIS_DATA, {
    variables: { deviceId },
  });
  return getIsRegis(data.device);
};

export default useIsRegis;
