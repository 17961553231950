import { useSuspenseQuery } from '@apollo/client';
import UsersListItem from '@features/access/components/usersTab/UsersListItem';
import StandardAutocompleteIdsWithFallback from '@features/standardDesign/components/autocomplete/StandardAutocompleteIds';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, TextField, Tooltip, Typography } from '@mui/material';
import Fuse from 'fuse.js';
import { useEffect, useState, type FC } from 'react';
import { FixedSizeList } from 'react-window';
import GET_USERS_ACCESS_FOR_DEVICE from '../graphql/queries/getUserAccessForDevice';
import { DeviceUserValue, UserOrganizationRole } from '../types';
import { DevicesFiltersMenu } from './DevicesFiltersMenu';
import { Access, AuthorizationWrapper } from '@features/authorization';

interface DevicesAccessForUserProps {
  deviceId: string;
  value: DeviceUserValue[];
  onChange: (values: DeviceUserValue[]) => void;
  /** @default 450 */
  height?: number;
}

const UsersAccessForDeviceInput: FC<DevicesAccessForUserProps> = ({
  deviceId,
  value,
  onChange,
  height,
}) => {
  const { _ } = useLingui();
  const { data } = useSuspenseQuery(GET_USERS_ACCESS_FOR_DEVICE, {
    variables: {
      deviceId,
      devicesUsersInput: {
        ignoreAccess: true,
      },
    },
  });
  const [search, setSearch] = useState('');
  const [filteredRelations, setFilteredRelations] = useState(data.device.users);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<number | null>(null);
  const [accessFilter, setAccessFilter] = useState<boolean | null>(null);
  const [roleFilter, setRoleFilter] = useState<UserOrganizationRole | null>(null);

  useEffect(() => {
    let filteredItems = data.device.users;

    if (selectedOrganizationId != null) {
      filteredItems = filteredItems.filter(
        (item) => item.user.mainOrganization?.organization.id === selectedOrganizationId,
      );
    }

    if (accessFilter !== null) {
      filteredItems = filteredItems.filter((relation) => relation.hasAccess === accessFilter);
    }

    if (roleFilter !== null) {
      filteredItems = filteredItems.filter(
        (relation) => relation.user.mainOrganization?.role.id === roleFilter,
      );
    }

    if (search) {
      const fuse = new Fuse(filteredItems, {
        keys: ['user.id', 'user.email', 'user.fullName'],
      });
      filteredItems = fuse.search(search).map(({ item }) => item);
    }

    setFilteredRelations(filteredItems);
  }, [search, data.device.users, selectedOrganizationId, accessFilter, roleFilter]);

  const onSearchChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearch(event.target.value);
  };

  const withAccessNumber = data.device.users.filter(({ hasAccess }) => hasAccess).length;
  const totalNumber = data.device.users.length;

  const userOrganizationIds = Array.from(
    new Set(
      data.device.users
        .map((user) => user.user.mainOrganization?.organization.id)
        .filter((organizationId) => organizationId),
    ),
  ) as number[];

  return (
    <>
      <Box mb={1} display="flex" justifyContent="stretch" alignItems="center" gap={1}>
        <Tooltip title={_(msg`Ver filtros`)}>
          <DevicesFiltersMenu
            accessFilter={accessFilter}
            setAccessFilter={setAccessFilter}
            roleFilter={roleFilter}
            setRoleFilter={setRoleFilter}
          />
        </Tooltip>
        <TextField
          value={search}
          onChange={onSearchChange}
          size="small"
          label={_(msg`Buscar`)}
          sx={{ flex: 3 }}
        />
        <AuthorizationWrapper access={Access.OrganizationsFilter} fallback={null}>
          <StandardAutocompleteIdsWithFallback
            sx={{ flex: 2 }}
            size="small"
            queryOptions={{
              organizations: {
                ids: userOrganizationIds,
              },
            }}
            dataType="organizations"
            value={selectedOrganizationId}
            disableClearable={false}
            onChange={(_, value) => {
              setSelectedOrganizationId(value);
            }}
          />
        </AuthorizationWrapper>
        <Typography textAlign="right" ml={2}>
          <Trans>
            <b>{withAccessNumber}</b> usuarios con acceso de <b>{totalNumber}</b>
          </Trans>
        </Typography>
      </Box>
      <Box border={1} borderColor="divider" borderRadius={1}>
        <FixedSizeList
          height={height ?? 450}
          width="100%"
          itemSize={56}
          itemCount={filteredRelations.length}
          itemKey={(index) => filteredRelations[index].id}
        >
          {({ style, index }) => {
            const deviceUserRelation = filteredRelations[index];
            const { user } = deviceUserRelation;
            const { hasAccess: formHasAccess } =
              value.find(({ id: relationId }) => relationId === deviceUserRelation.id) ?? {};
            const hasAccess = formHasAccess ?? deviceUserRelation.hasAccess;
            const modified = formHasAccess != null;

            const userOrganization = user.mainOrganization
              ? {
                  id: user.mainOrganization.organization.id,
                  name: user.mainOrganization.organization.name,
                  roleId: user.mainOrganization.role.id,
                }
              : null;

            const onClick = () => {
              const newValues = value.filter(
                ({ id: relationId }) => relationId !== deviceUserRelation.id,
              );
              const newValue = {
                id: deviceUserRelation.id,
                hasAccess: !hasAccess,
              };
              if (newValue.hasAccess === deviceUserRelation.hasAccess) onChange(newValues);
              else onChange([...newValues, newValue]);
            };

            return (
              <UsersListItem
                key={user.id}
                style={style}
                lastItem={index === filteredRelations.length - 1}
                userId={user.id}
                userName={user.fullName}
                userEmail={user.email}
                userOrganization={userOrganization}
                onClick={onClick}
                hasAccess={hasAccess}
                modified={modified}
              />
            );
          }}
        </FixedSizeList>
      </Box>
    </>
  );
};

export default UsersAccessForDeviceInput;
