import { GateDataConfiguration } from '@/__generated__/graphql';
import { useModalStatusMessage } from '@/hooks';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import { UPDATE_GATE_POSITION_LIMITS } from '@features/deviceConfigurationModal/graphql/mutations/updateGatePositionLimits';
import { GET_GATE_TAB_DATA } from '@features/deviceConfigurationModal/graphql/queries/getGateTabData';
import { zodResolver } from '@hookform/resolvers/zod';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useEffect } from 'react';
import { type DefaultValues, useForm } from 'react-hook-form';
import { type FormValues, getUpdateGateInputSchema } from './updateSchema';

const getDefaultValues = (gateId: string, data: Partial<GateDataConfiguration>) => {
  const activeConfiguration: DefaultValues<FormValues> = {
    id: gateId,
    min: data.minPosition ?? undefined,
    max: data.maxPosition ?? undefined,
  };
  return activeConfiguration;
};

export const useUpdateForm = (gateId: string) => {
  const { _, i18n } = useLingui();
  const { openModalSuccessMessage, openModalErrorMessage } = useModalStatusMessage();
  const {
    data: { gate },
  } = useSuspenseQuery(GET_GATE_TAB_DATA, {
    variables: { gateId },
  });

  const methods = useForm<FormValues>({
    defaultValues: getDefaultValues(gateId, gate.dataConfiguration),
    resolver: zodResolver(getUpdateGateInputSchema(i18n)),
  });

  const [mutate] = useMutation(UPDATE_GATE_POSITION_LIMITS, {
    onCompleted({ updateGate: { dataConfiguration } }) {
      openModalSuccessMessage(_(msg`Configuración de alertas actualizada correctamente`));
      methods.reset(getDefaultValues(gateId, dataConfiguration));
    },
    onError(error) {
      openModalErrorMessage(
        _(msg`Error al actualizar la configuración de alertas: ${error.message}`),
      );
    },
  });

  useEffect(() => {
    methods.reset(getDefaultValues(gateId, gate.dataConfiguration));
  }, [gateId, gate.dataConfiguration, methods]);

  const onSubmit = methods.handleSubmit((data) => {
    mutate({
      variables: {
        gateId: data.id,
        min: data.min,
        max: data.max,
      },
    });
  });

  return {
    methods,
    onSubmit,
    gate,
  };
};
