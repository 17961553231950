import { gql } from '@/__generated__';

export const UPDATE_GATE_ALARM = gql(`#graphql
  mutation UpdateGateAlarm($gateId: ID!, $alarmData: String!) {
    updateGate(input: {
      id: $gateId
      alarmData: $alarmData
    }) {
      id
      hasAlarm
      alarmData
    }
  }
`);
