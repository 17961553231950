import { gql } from '@/__generated__';

export const DISABLE_DEVICE_ALERTS = gql(`#graphql
  mutation DisableDeviceAlertsModal($deviceId: ID!) {
    disableAlertConfiguration(input: { deviceId: $deviceId }) {
      id
      basicConfigurations {
        id
        lastFlow {
          id
          timestamp
          min
          max
          overflow
          unit
          precision
        }
        lastLevel {
          id
          timestamp
          min
          max
          overflow
          unit
          precision
        }
      }
    }
  }
`);
