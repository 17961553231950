import { gql } from '@/__generated__';

export const GET_DEVICE_PUBLIC_TAB_DATA = gql(`#graphql
  query DeviceConfiguration_GetDevicePublicTabData($deviceId: ID!) {
    device(input: { id: $deviceId }) {
      id
      profile {
        id
        organization {
          id
          name
        }
      }
      publicDevice {
        id
      }
    }
  }
`);
