import ApolloErrorBoundary from '@components/ApolloErrorBoundary';
import ModalActions from '@components/modal/ModalActions';
import { Box, Button, Typography } from '@mui/material';
import { Suspense, useEffect, type FC } from 'react';
import { Controller } from 'react-hook-form';
import { TabContent, TabFallback, type TabProps } from '../common';
import { FormComponent } from './FormComponent';
import { useUpdateForm } from './useUpdateForm';
import { Trans } from '@lingui/macro';
import { useDispatch } from '@/store';
import { setUpdateDeviceAlertsModal, setUpsertAlertAdvancedCreationModal } from '@/slices/modals';

// TODO
export const AlertConfigurationTab: FC<TabProps> = ({ deviceId, onClose, setIsDirty }) => {
  const dispatch = useDispatch();
  // const { methods, onSubmit } = useUpdateForm(deviceId);
  //
  // useEffect(() => {
  //   setIsDirty(methods.formState.isDirty);
  // }, [methods.formState.isDirty, setIsDirty]);

  const onClick = () => {
    dispatch(
      setUpsertAlertAdvancedCreationModal({
        open: true,
        mode: 'update',
        initialDeviceId: deviceId,
      }),
    );
  };

  return (
    <>
      <TabContent>
        <Typography variant="h6" mt={1}>
          <Trans>Configuración avanzada</Trans>
        </Typography>
        <Box py={2}>
          <Button variant="contained" onClick={onClick}>
            Abrir modal de alertas avanzadas
          </Button>
        </Box>
      </TabContent>
      <ModalActions onClose={onClose} onSubmit="submit" dirtyForm={false} />
    </>
  );

  // return (
  //   <ApolloErrorBoundary variant="modal">
  //     <Suspense fallback={<TabFallback onClose={onClose} />}>
  //       <form onSubmit={onSubmit}>
  //         <Box display="flex" flexDirection="column">
  //           <TabContent>
  //             <Controller
  //               control={methods.control}
  //               name="input"
  //               render={({ field }) => {
  //                 return (
  //                   <FormComponent
  //                     deviceId={deviceId}
  //                     value={field.value}
  //                     onChange={field.onChange}
  //                   />
  //                 );
  //               }}
  //             />
  //           </TabContent>
  //           <ModalActions
  //             onClose={onClose}
  //             onSubmit="submit"
  //             dirtyForm={methods.formState.isDirty}
  //             submitLoading={methods.formState.isSubmitting}
  //           />
  //         </Box>
  //       </form>
  //     </Suspense>
  //   </ApolloErrorBoundary>
  // );
};
