import { DeviceAlertMetricName } from '@/__generated__/graphql';
import { type I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { z } from 'zod';

export const getUpdateBasicAlertConfigurationInputSchema = (i18n: I18n) =>
  z
    .object({
      deviceId: z.string(),
      alertType: z.enum([DeviceAlertMetricName.Flow, DeviceAlertMetricName.Level]),
      min: z.number().nonnegative(),
      max: z.number().positive(),
      overflow: z.number().positive(),
    })
    .refine((data) => data.max > data.min, {
      message: t(i18n)`El máximo debe ser mayor al mínimo`,
      path: ['max'],
    })
    .refine((data) => data.overflow > data.max, {
      message: t(i18n)`El valor de desborde debe ser mayor al máximo`,
      path: ['overflow'],
    });

export type FormValues = z.infer<ReturnType<typeof getUpdateBasicAlertConfigurationInputSchema>>;
