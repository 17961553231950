import { useSubscription } from '@apollo/client';
import GATE_FINISHED_MOVING from '../graphql/subscriptions/gateFinishedMoving';
import GATE_MOVED from '../graphql/subscriptions/gateMoved';
import GATE_STARTED_MOVING from '../graphql/subscriptions/gateStartedMoving';
import { useDispatch } from 'react-redux';
import { setGateInMovement, removeGateInMovement } from '../slices/reducer';
import { client } from '@/graphql';
import GET_GATE_MOVEMENTS from '../graphql/queries/getGateMovementHistory';
import { useSelector } from '@/store';

export const useGatesMovementGlobalActions = () => {
  const { gatesInMovement } = useSelector((state) => state.gates_in_movement_store);
  const dispatch = useDispatch();
  useSubscription(GATE_STARTED_MOVING, {
    onData: ({ data: { data } }) => {
      if (!data) return;
      dispatch(
        setGateInMovement({
          ...data.gateStartedMoving,
          state: 'STARTED_MOVING',
        }),
      );
    },
  });
  useSubscription(GATE_FINISHED_MOVING, {
    onData: ({ data: { data } }) => {
      if (!data) return;
      client.refetchQueries({
        include: [GET_GATE_MOVEMENTS],
      });
      dispatch(
        setGateInMovement({
          ...data.gateFinishedMoving,
          state: 'FINISHED_MOVING',
        }),
      );

      // dismiss the banner after 10 seconds and refetch the gates
      const timer = setTimeout(() => {
        dispatch(
          removeGateInMovement({
            gateId: data.gateFinishedMoving.gateId,
          }),
        );
        client.refetchQueries({
          include: [GET_GATE_MOVEMENTS],
        });
      }, 15000);

      return () => clearTimeout(timer);
    },
  });

  useSubscription(GATE_MOVED, {
    onData: ({ data: { data } }) => {
      if (!data) return;

      // don't dispatch the action if the gate has already finished moving
      const gate = gatesInMovement.find((gate) => gate.gateId === data.gateMoved.gateId);
      if (gate && gate.state === 'FINISHED_MOVING') return;

      dispatch(
        setGateInMovement({
          ...data.gateMoved,
          state: 'MOVING',
        }),
      );
    },
  });
};
