/**
 * Global modals redux.
 *
 * To add a modal state just add an entry on the `initialState`.
 *
 * If using `useGlobalModal` hoook is not necessary to add the action to the export list,
 * the hook uses just the `actions` export.
 */

import {
  SmaDeviceMetric,
  SmaMetricName,
  WriteExecutionLogEntityType,
  WriteExecutionLogSeverity,
} from '@/__generated__/graphql';
import { type DeviceAlertsModalTab } from '@features/deviceAlertsModal/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

/**
 * @example
 * ModalStateRequiredKey<'deviceId', string> = {
 *   open: true;
 *   deviceId: string;
 * } | {
 *   open: false;
 * }
 */
type ModalStateRequiredKey<TKey extends string, TValue> =
  | ({
      open: true;
    } & {
      [K in TKey]: TValue;
    })
  | {
      open: false;
    };

/**
 * @example
 * ModalStateOptionalKey<'deviceId', string> = {
 *   open: boolean;
 *   deviceId?: string;
 * }
 */
type ModalStateOptionalKey<TKey extends string, TValue> = {
  open: boolean;
} & {
  [K in TKey]?: TValue;
};

type ClosedModal = { open: false };
type OpenModal<T> = { open: true } & T;

const initialState = {
  updateDeviceAlertsModal: {
    open: false,
  } as ModalStateOptionalKey<'deviceId', string>,
  createDeviceObservationModal: {
    open: false,
  } as ModalStateRequiredKey<'deviceId', string>,
  updateDeviceObservationModal: {
    open: false,
  } as ModalStateRequiredKey<'observationId', number>,
  deleteDeviceObservationModal: {
    open: false,
  } as ModalStateRequiredKey<'observationId', number>,
  updateDeviceDataConfigurationModal: {
    open: false,
  } as ModalStateRequiredKey<'deviceId', string>,
  /** @deprecated */
  deprecatedCreateMeasurementModal: {
    open: false,
  } as ModalStateRequiredKey<'deviceId', string>,
  /** @deprecated */
  deprecatedMeasurementsTableModal: {
    open: false,
  } as ModalStateRequiredKey<'deviceId', string>,
  upsertSmaConfigurationModal: {
    open: false,
  } as
    | {
        open: true;
        mode: 'update' | 'create';
        initialSmaConfig?: SmaDeviceMetric;
        initialDeviceId?: string;
      }
    | {
        open: false;
        mode?: 'update' | 'create';
        initialSmaConfig?: SmaDeviceMetric;
        initialDeviceId?: string;
      },
  smaSuccessTableModal: {
    open: false,
  } as
    | {
        open: false;
      }
    | {
        open: true;
        deviceId: string;
        smaUnitId: string;
        smaDeviceId: string;
        smaProcessId: string;
        smaMetricName: string;
      },
  smaReportsTableModal: {
    open: false,
  } as
    | {
        open: false;
      }
    | {
        open: true;
        deviceId: string;
        smaUnitId: string;
        smaDeviceId: string;
        smaProcessId: string;
        smaMetricName: SmaMetricName;
      },
  upsertAlertAdvancedCreationModal: {
    open: false,
  } as
    | {
        open: true;
        mode: 'update' | 'create';
        initialDeviceId?: string;
      }
    | {
        open: false;
        mode?: 'update' | 'create';
        initialDeviceId?: string;
      },

  saveUserModal: {
    open: false,
  } as ModalStateOptionalKey<'userId', number>,
  simulateUserModal: {
    open: false,
  } as ModalStateRequiredKey<'userId', number>,
  deleteUserModal: {
    open: false,
  } as ModalStateRequiredKey<'userId', number>,
  userProfileModal: {
    open: false,
  } as ModalStateRequiredKey<'userId', number>,
  createTemporaryUserModal: {
    open: false as boolean,
  },
  extendTemporaryUserModal: {
    open: false,
  } as ModalStateOptionalKey<'userId', number>,
  extendTemporaryUserTokenModal: {
    open: false,
  } as ModalStateOptionalKey<'userId', number>,
  saveHardwareConfigurationModal: {
    open: false,
  } as ModalStateRequiredKey<'deviceId', string>,
  updateDGAConfigurationModal: {
    open: false,
  } as ModalStateRequiredKey<'deviceId', string>,
  deleteDGAConfigurationModal: {
    open: false,
  } as ModalStateRequiredKey<'deviceId', string>,
  updateDeviceConfigurationModal: {
    open: false,
  } as ModalStateRequiredKey<'deviceId', string>,
  updateDeviceAliasesModal: {
    open: false,
  } as ModalStateRequiredKey<'deviceId', string>,
  deleteDeviceModal: {
    open: false,
  } as ModalStateRequiredKey<'deviceId', string>,
  cloneDeviceModal: {
    open: false,
  } as ModalStateRequiredKey<'deviceId', string>,
  addDeviceUserRelationsModal: {
    open: false,
  } as
    | {
        open: false;
      }
    | ({
        open: true;
      } & (
        | {
            fixedDeviceId?: string;
          }
        | {
            fixedUserId?: number;
          }
      )),
  removeDeviceUserRelationsModal: {
    open: false,
  } as
    | {
        open: false;
      }
    | ({
        open: true;
      } & (
        | {
            fixedDeviceId?: string;
          }
        | {
            fixedUserId?: number;
          }
      )),
  devicesGraveyardModal: {
    open: false,
  },
  updateDeviceStatusModal: {
    open: false,
  } as ModalStateRequiredKey<'deviceId', string>,
  viewEntityGroupedIssues: {
    open: false,
  } as
    | { open: false }
    | {
        open: true;
        selectedSeverityFilter?: WriteExecutionLogSeverity;
        entity: {
          type: WriteExecutionLogEntityType;
          id: number;
        };
      },
  deviceAlerts: {
    open: false,
  } as
    | ClosedModal
    | OpenModal<{
        deviceId: string;
        selectedTab?: DeviceAlertsModalTab;
      }>,
  deviceAlertsRawConfiguration: {
    open: false,
  } as ModalStateRequiredKey<'deviceId', string>,
  disableDeviceAlertsConfiguration: {
    open: false,
  } as ModalStateRequiredKey<'deviceId', string>,
};

export type ModalsState = typeof initialState;

type SetStateAction<TKey extends keyof ModalsState> = PayloadAction<ModalsState[TKey]>;

/**
 * Generic set state action.
 */
const setModalState =
  <TKey extends keyof ModalsState>(key: TKey) =>
  (state: ModalsState, action: SetStateAction<TKey>) => {
    state[key] = action.payload;
  };

/**
 * Generate reducers from the `initialState` object
 */
const reducers = (Object.keys(initialState) as Array<keyof ModalsState>).reduce(
  (prev, key) => {
    const formattedKey = `set${key.charAt(0).toUpperCase()}${key.slice(1)}` as keyof typeof prev;
    prev[formattedKey] = setModalState(key);
    return prev;
  },
  {} as {
    [K in keyof ModalsState as `set${Capitalize<K>}`]: ReturnType<typeof setModalState<K>>;
  },
);

const slice = createSlice({
  name: 'modals_store',
  initialState,
  reducers,
});

export default slice.reducer;
export const actions = { ...slice.actions };
export const {
  setUpdateDeviceAlertsModal,
  setCreateDeviceObservationModal,
  setUpdateDeviceObservationModal,
  setDeleteDeviceObservationModal,
  setUpdateDeviceDataConfigurationModal,
  setDeprecatedMeasurementsTableModal,
  setDeprecatedCreateMeasurementModal,
  setUpsertSmaConfigurationModal,
  setSmaSuccessTableModal,
  setSmaReportsTableModal,
  setUpsertAlertAdvancedCreationModal,
  setSaveUserModal,
  setSimulateUserModal,
  setDeleteUserModal,
  setUserProfileModal,
  setCreateTemporaryUserModal,
  setExtendTemporaryUserModal,
  setExtendTemporaryUserTokenModal,
  setSaveHardwareConfigurationModal,
  setUpdateDGAConfigurationModal,
  setDeleteDGAConfigurationModal,
  setUpdateDeviceConfigurationModal,
  setUpdateDeviceAliasesModal,
} = slice.actions;
