import { useModalStatusMessage } from '@/hooks';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import { UPDATE_PUBLIC_DEVICE } from '@features/deviceConfigurationModal/graphql/mutations/updatePublicDevice';
import { GET_DEVICE_PUBLIC_TAB_DATA } from '@features/deviceConfigurationModal/graphql/queries/getDevicePublicTabData';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type FormValues = {
  deviceId: string;
  public: boolean;
};

export const useUpdateForm = (deviceId: string) => {
  const { _ } = useLingui();
  const { openModalSuccessMessage, openModalErrorMessage } = useModalStatusMessage();
  const {
    data: { device },
  } = useSuspenseQuery(GET_DEVICE_PUBLIC_TAB_DATA, {
    variables: {
      deviceId,
    },
  });

  const methods = useForm<FormValues>({
    defaultValues: {
      deviceId,
      public: device.publicDevice != null,
    },
  });

  const [mutate] = useMutation(UPDATE_PUBLIC_DEVICE, {
    onCompleted({ updateDevice }) {
      openModalSuccessMessage(_(msg`Dispositivo público actualizado correctamente`));
      methods.reset({
        deviceId,
        public: updateDevice.publicDevice != null,
      });
    },
    onError(error) {
      openModalErrorMessage(_(msg`Error al actualizar dispositivo: ${error.message}`));
    },
  });

  useEffect(() => {
    methods.reset({ deviceId, public: device.publicDevice != null });
  }, [methods]);

  const onSubmit = methods.handleSubmit((data) => {
    mutate({
      variables: data,
    });
  });

  return {
    methods,
    onSubmit,
    device,
  };
};
