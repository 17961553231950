import { gql } from 'src/__generated__';

const DISABLE_DEVICE_ALERTS = gql(`#graphql
  mutation DisableDeviceAlerts($deviceId: ID!) {
    disableAlertConfiguration(input: { deviceId: $deviceId }) {
      id
      basicConfigurations {
        id
        lastFlow {
          id
          timestamp
          min
          max
          overflow
          unit
          precision
        }
        lastLevel {
          id
          timestamp
          min
          max
          overflow
          unit
          precision
        }
      }
    }
  }
`);
export default DISABLE_DEVICE_ALERTS;
