import { gql } from '@/__generated__';

export const UPDATE_BASIC_ALERT_CONFIGURATION = gql(`#graphql
  mutation UpdateBasicAlertConfiguration($input: UpdateBasicAlertConfigurationInput!) {
    updateBasicAlertConfiguration(input: $input) {
      id
      basicConfigurations {
        id
        lastFlow {
          id
          timestamp
          min
          max
          overflow
          unit
          precision
        }
        lastLevel {
          id
          timestamp
          min
          max
          overflow
          unit
          precision
        }
      }
    }
  }
`);
