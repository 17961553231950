import { useGlobalModal } from '@/hooks/useGlobalModal';
import DeviceModalTitle, { DeviceModalTitleAction } from '@components/DeviceModalTitle';
import GlobalModal from '@components/GlobalModal';
import ModalTabs, { Panel } from '@components/ModalTabs';
import { Access } from '@features/authorization';
import { type I18n } from '@lingui/core';
import { msg, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { DataObject, SensorsOff } from '@mui/icons-material';
import { useCallback, useEffect, useState, type FC } from 'react';
import { DeviceAlertsModalTab } from '../types';
import { AlertConfigurationTab } from './alertConfigurationTab';
import { BasicConfigurationTab } from './basicConfigurationTab';
import { NotificationsTab } from './notificationConfigurationTab';

export const DeviceAlertsModal: FC = () => {
  const { i18n } = useLingui();
  const { globalModalProps, state, onClose, changeTabMiddleware, setIsDirty } = useGlobalModal(
    'deviceAlerts',
    {
      closeConfirmation: true,
    },
  );

  const { panels, value, setValue } = usePanels({
    deviceId: 'deviceId' in state ? state.deviceId : null,
    initialValue: 'selectedTab' in state ? state.selectedTab ?? null : null,
    onClose,
    setIsDirty,
    i18n,
  });

  const actions = useActions({
    deviceId: 'deviceId' in state ? state.deviceId : null,
  });

  return (
    <GlobalModal
      {...globalModalProps}
      dialogProps={{ maxWidth: 'xl' }}
      authorization={{
        access: Access.DeviceAlertsModal,
        deviceId: state.open ? state.deviceId : undefined,
        onClose,
      }}
    >
      {state.open && (
        <>
          <DeviceModalTitle actions={actions} deviceId={state.deviceId} onClose={onClose} />
          <ModalTabs
            value={value}
            setValue={setValue}
            deviceId={state.deviceId}
            changeTabMiddleware={changeTabMiddleware}
            panels={panels}
          />
        </>
      )}
    </GlobalModal>
  );
};

const usePanels = ({
  deviceId,
  initialValue,
  onClose,
  setIsDirty,
  i18n,
}: {
  deviceId: string | null;
  initialValue: DeviceAlertsModalTab | null;
  onClose: () => void;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  i18n: I18n;
}): {
  panels: Array<Panel<DeviceAlertsModalTab>>;
  value: DeviceAlertsModalTab;
  setValue: React.Dispatch<React.SetStateAction<DeviceAlertsModalTab>>;
} => {
  const [selectedTab, setSelectedTab] = useState(DeviceAlertsModalTab.BasicConfiguration);

  useEffect(() => {
    setSelectedTab(initialValue ?? DeviceAlertsModalTab.BasicConfiguration);
  }, [initialValue, setSelectedTab]);

  if (!deviceId)
    return {
      panels: [],
      value: selectedTab,
      setValue: setSelectedTab,
    };

  const props = {
    deviceId,
    onClose,
    setIsDirty,
  };

  return {
    panels: [
      {
        key: DeviceAlertsModalTab.BasicConfiguration,
        label: t(i18n)`Configuración básica`,
        component: <BasicConfigurationTab {...props} />,
        access: Access.UpdateDeviceAlertsBasicConfiguration,
      },
      {
        key: DeviceAlertsModalTab.Configuration,
        label: t(i18n)`Configuración avanzada`,
        component: <AlertConfigurationTab {...props} />,
        access: Access.UpdateDeviceAlertsConfiguration,
      },
      {
        key: DeviceAlertsModalTab.NotificationsConfiguration,
        label: t(i18n)`Notificaciones`,
        component: <NotificationsTab {...props} />,
        access: Access.UpdateDeviceAlertNotificationsConfiguration,
      },
    ],
    value: selectedTab,
    setValue: setSelectedTab,
  };
};

const useActions = ({ deviceId }: { deviceId: string | null }) => {
  const { _ } = useLingui();
  const { setModalState: setAlertsRawConfigurationModal } = useGlobalModal(
    'deviceAlertsRawConfiguration',
  );
  const { setModalState: setDisableAlertsModal } = useGlobalModal(
    'disableDeviceAlertsConfiguration',
  );

  const onShowRawDataClick = useCallback(() => {
    if (deviceId) setAlertsRawConfigurationModal({ open: true, deviceId });
  }, [deviceId, setAlertsRawConfigurationModal]);

  const onDisableAlertsClick = useCallback(() => {
    if (deviceId) setDisableAlertsModal({ open: true, deviceId });
  }, [deviceId, setDisableAlertsModal]);

  const actions: Array<DeviceModalTitleAction> = [
    {
      key: 'raw-data',
      label: _(msg`Mostrar configuración bruta`),
      color: 'info',
      icon: <DataObject />,
      // TODO: change access
      // access: Access.ShowRawDeviceAlertsData,
      access: Access.ShowRawDeviceAlertsData,
      onClick: onShowRawDataClick,
    },
    {
      key: 'disable-alerts',
      label: _(msg`Desactivar alertas`),
      color: 'warning',
      icon: <SensorsOff />,
      access: Access.DisableDeviceAlertsConfiguration,
      onClick: onDisableAlertsClick,
    },
  ];
  return actions;
};
