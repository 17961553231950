import { gql } from '@/__generated__';

export const UPDATE_ALERT_NOTIFICATION_CONFIGURATIONS = gql(`#graphql
  mutation UpdateAlertNotificationConfigurations($input: [UpdateAlertNotificationConfigurationInput!]!) {
    updateAlertNotificationConfigurations(input: $input) {
      id
      notifyEmail
      notifySms
      notifyWhatsapp
      notifyPush
      deviceUserRelation {
        id
        deviceId
      }
    }
  }
`);
