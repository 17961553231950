import { WriteExecutionLogSeverity } from '@/__generated__/graphql';
import { ModalsState } from '@/slices/modals';
import { Box } from '@mui/material';
import { type FC } from 'react';
import { SeverityButton } from '../SeverityButton';

interface Props {
  severity: WriteExecutionLogSeverity;
  onSeverityChange: (severity: WriteExecutionLogSeverity) => void;
  entity: Extract<ModalsState['viewEntityGroupedIssues'], { open: true }>['entity'];
}

export const Filters: FC<Props> = ({ severity: selectedSeverity, onSeverityChange, entity }) => {
  return (
    <Box display="flex" gap={1} mb={2}>
      {[
        WriteExecutionLogSeverity.Info,
        WriteExecutionLogSeverity.Warning,
        WriteExecutionLogSeverity.Error,
        WriteExecutionLogSeverity.Critical,
      ].map((severity) => {
        const active = selectedSeverity === severity;

        return (
          <SeverityButton
            key={severity}
            active={active}
            severity={severity}
            onClick={() => onSeverityChange(severity)}
            entity={entity}
            ButtonProps={{
              sx: {
                height: 36,
              },
            }}
          />
        );
      })}
    </Box>
  );
};
