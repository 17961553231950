import { type I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { z } from 'zod';

export const getUpdateGateInputSchema = (i18n: I18n) =>
  z
    .object({
      id: z.string(),
      min: z.number().nonnegative(),
      max: z.number().positive(),
    })
    .refine((data) => data.max > data.min, {
      message: t(i18n)`El máximo debe ser mayor al mínimo`,
      path: ['max'],
    });

export type FormValues = z.infer<ReturnType<typeof getUpdateGateInputSchema>>;
