import { GateLinkedDeviceDirection } from '@/__generated__/graphql';
import ApolloErrorBoundary from '@components/ApolloErrorBoundary';
import { NumericTextFieldController } from '@components/controllers/NumericTextFieldController';
import ModalActions from '@components/modal/ModalActions';
import DeviceTooltip from '@components/tooltips/DeviceTooltip';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, Button, InputAdornment, Typography } from '@mui/material';
import { Suspense, useEffect, useState, type FC } from 'react';
import { TabContent, TabFallback } from '../common';
import { DeviceTabProps } from '../tabProps';
import UpdateAlarm from './alarm/UpdateAlarm';
import { CreateLinkedDeviceButton } from './CreateLinkedDeviceButton';
import { useUpdateForm } from './useUpdateForm';

const SuspenseComponent: FC<DeviceTabProps> = ({ deviceId, onClose, setIsDirty }) => {
  const { _ } = useLingui();
  const [openAlarmModal, setOpenAlarmModal] = useState(false);

  const { methods, onSubmit, gate } = useUpdateForm(deviceId);

  useEffect(() => {
    setIsDirty(methods.formState.isDirty);
  }, [methods.formState.isDirty, setIsDirty]);

  return (
    <form onSubmit={onSubmit}>
      <TabContent>
        <Typography variant="h6" mb={2}>
          <Trans>Límites de abertura</Trans>
        </Typography>
        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" alignItems="center" gap={2}>
          <NumericTextFieldController
            control={methods.control}
            name="min"
            label={_(msg`Abertura mínima`)}
            slotProps={{
              textField: {
                InputProps: {
                  endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                },
              },
            }}
          />
          <NumericTextFieldController
            control={methods.control}
            name="max"
            label={_(msg`Abertura máxima`)}
            slotProps={{
              textField: {
                InputProps: {
                  endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                },
              },
            }}
          />
        </Box>
        <Typography variant="h6" mt={4} mb={2}>
          <Trans>Estaciones virtuales</Trans>
        </Typography>
        <Box px={0.5}>
          <Box mb={2}>
            <Typography
              fontWeight="bold"
              textTransform="uppercase"
              fontSize="small"
              color="text.secondary"
            >
              <Trans>Aguas arriba</Trans>
            </Typography>
            {gate.upstreamDevice ? (
              <DeviceTooltip deviceId={gate.upstreamDevice.id} placement="bottom-start">
                <Typography>{gate.upstreamDevice.profile.name}</Typography>
              </DeviceTooltip>
            ) : (
              <CreateLinkedDeviceButton
                gateId={deviceId}
                direction={GateLinkedDeviceDirection.Upstream}
              />
            )}
          </Box>
          <Box>
            <Typography
              fontWeight="bold"
              textTransform="uppercase"
              fontSize="small"
              color="text.secondary"
            >
              <Trans>Aguas abajo</Trans>
            </Typography>
            {gate.downstreamDevice ? (
              <DeviceTooltip deviceId={gate.downstreamDevice.id} placement="bottom-start">
                <Typography>{gate.downstreamDevice.profile.name}</Typography>
              </DeviceTooltip>
            ) : (
              <CreateLinkedDeviceButton
                gateId={deviceId}
                direction={GateLinkedDeviceDirection.Downstream}
              />
            )}
          </Box>
        </Box>
        <Typography variant="h6" mt={4} mb={2}>
          <Trans>Configurar alarma</Trans>
        </Typography>
        <Button variant="contained" onClick={() => setOpenAlarmModal(true)}>
          <Trans>Editar usuarios a notificar</Trans>
        </Button>
        <UpdateAlarm
          gateId={deviceId}
          gateAlarmData={gate.alarmData}
          open={openAlarmModal}
          onClose={() => setOpenAlarmModal(false)}
        />
      </TabContent>
      <ModalActions
        onClose={onClose}
        onSubmit="submit"
        dirtyForm={methods.formState.isDirty}
        submitLoading={methods.formState.isSubmitting}
        onResetForm={() => methods.reset()}
      />
    </form>
  );
};

export const GateTab: FC<DeviceTabProps> = (props) => {
  return (
    <ApolloErrorBoundary variant="modal">
      <Suspense fallback={<TabFallback {...props} />}>
        <SuspenseComponent {...props} />
      </Suspense>
    </ApolloErrorBoundary>
  );
};
