import { gql } from '@/__generated__';

export const UPDATE_PUBLIC_DEVICE = gql(`#graphql
  mutation DeviceConfiguration_UpdatePublicDevice($deviceId: ID!, $public: Boolean!) {
    updateDevice(deviceId: $deviceId, input: { public: $public }) {
      id
      publicDevice {
        id
      }
    }
  }
`);
