import ApolloErrorBoundary from '@components/ApolloErrorBoundary';
import ModalActions from '@components/modal/ModalActions';
import { Trans } from '@lingui/macro';
import { Box, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import { Suspense, useEffect, type FC } from 'react';
import { Controller } from 'react-hook-form';
import { TabContent, TabFallback } from '../common';
import { DeviceTabProps } from '../tabProps';
import { useUpdateForm } from './useUpdateForm';

const SuspenseComponent: FC<DeviceTabProps> = ({ deviceId, onClose, setIsDirty }) => {
  const { methods, onSubmit, device } = useUpdateForm(deviceId);

  useEffect(() => {
    setIsDirty(methods.formState.isDirty);
  }, [methods.formState.isDirty, setIsDirty]);

  return (
    <form onSubmit={onSubmit}>
      <TabContent>
        <Typography variant="h6" mb={2}>
          <Trans>Dispositivo público</Trans>
        </Typography>
        <Box px={2}>
          <Controller
            control={methods.control}
            name="public"
            render={({ field }) => (
              <FormGroup>
                <FormControlLabel
                  checked={field.value}
                  onChange={field.onChange}
                  control={<Switch />}
                  label={
                    <Trans>
                      Activar dispositivo público para la organización{' '}
                      <b>{device.profile.organization?.name}</b>
                    </Trans>
                  }
                />
              </FormGroup>
            )}
          />
        </Box>
      </TabContent>
      <ModalActions
        onClose={onClose}
        onSubmit="submit"
        dirtyForm={methods.formState.isDirty}
        submitLoading={methods.formState.isSubmitting}
        onResetForm={() => methods.reset()}
      />
    </form>
  );
};

export const PublicTab: FC<DeviceTabProps> = (props) => {
  return (
    <ApolloErrorBoundary variant="modal">
      <Suspense fallback={<TabFallback {...props} />}>
        <SuspenseComponent {...props} />
      </Suspense>
    </ApolloErrorBoundary>
  );
};
