import { gql } from '@/__generated__';

export const UPDATE_GATE_POSITION_LIMITS = gql(`#graphql
  mutation UpdateGatePositionLimits($gateId: ID!, $min: Float!, $max: Float!) {
    updateGate(input: {
      id: $gateId
      minPosition: $min
      maxPosition: $max
    }) {
      id
      dataConfiguration {
        maxPosition
        minPosition
        calibrationDownstream
        calibrationEncoder
        calibrationUpstream
      }
    }
  }
`);
