import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import { Gate } from 'src/types/gates/gate';
import { System } from 'src/types/system/system';
import moment from 'moment';

interface GatesState {
  gates: Gate[] | null;
  selectedGate: Gate | null;
  // showCreate: boolean;
  isReadyToOperate: boolean;
  showUpstream: boolean;
  showDownstream: boolean;
  timeRange: number;
  reloadInterval: number;
  lastUpdate: string;
  refetch: boolean;
  system: System | null;
  showScheduleMove: boolean;
  showScheduleHistory: boolean;
  showUpdateScheduleMove: boolean;
  updateScheduleMove: any | null;
  showBatterySoc: boolean;
  showSolarV: boolean;
  gateIsMoving: boolean;
}

const initialState: GatesState = {
  gates: null,
  selectedGate: null,
  // showCreate: false,
  isReadyToOperate: false,
  showUpstream: true,
  showDownstream: true,
  timeRange: 30, // minutes
  reloadInterval: 30, // seconds
  lastUpdate: moment().toISOString(),
  refetch: false,
  system: null,
  showScheduleMove: false,
  showScheduleHistory: false,
  showUpdateScheduleMove: false,
  updateScheduleMove: null,
  showBatterySoc: true,
  showSolarV: true,
  gateIsMoving: false,
};

const slice = createSlice({
  name: 'gates_state',
  initialState,
  reducers: {
    restore() {
      return initialState;
    },
    setSelectedGate: (state, action: PayloadAction<Gate | null>) => {
      state.selectedGate = action.payload;
    },
  },
});

export const { reducer } = slice;

export const restoreGateStore = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.restore());
};

export const setSelectedGate =
  (gate: Gate | null): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSelectedGate(gate));
  };
