import { Access, useSuspenseAuthorization } from '@features/authorization';
import { Trans } from '@lingui/macro';
import { BeachAccess } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Skeleton, Tab, Typography } from '@mui/material';
import { Suspense, useCallback, useMemo, useState, type FC } from 'react';

export type Panel<T extends string = string> = {
  key: T;
  label: string;
  component: JSX.Element | string;
  access?: Access;
};

export type ChangeTabMiddleware<T extends string = string> = (
  next: () => void,
  state: { previousPanel: Panel<T>; nextPanel: Panel<T> },
) => void;

interface Props<T extends string> {
  /**
   * Array of panels to render.
   */
  panels: Panel<T>[];
  /**
   * Function to execute when the user changes the tab.
   * This function will be executed before the tab changes.
   * @default undefined
   **/
  changeTabMiddleware?: ChangeTabMiddleware<NoInfer<T>>;
  value?: NoInfer<T>;
  setValue?: (newValue: NoInfer<T>) => void;
  /**
   * Used to check access on each panel
   */
  deviceId?: string;
}

const Component = <T extends string>({
  panels,
  changeTabMiddleware,
  value: controlledValue,
  setValue: setControlledValue,
  deviceId,
}: Props<T>) => {
  const { validateAccess } = useSuspenseAuthorization(deviceId);

  const authorizedPanels = useMemo(() => {
    return panels.filter((panel) => (panel.access ? validateAccess(panel.access) : true));
  }, [panels, validateAccess]);

  const [value, setValue] = useState<T>(authorizedPanels[0]?.key ?? '');

  const validControlledValue =
    (authorizedPanels.find((panel) => panel.key === controlledValue) ?? authorizedPanels[0])?.key ??
    '';
  const valueFinal = controlledValue == null ? value : validControlledValue;
  const setValueFinal = setControlledValue == null ? setValue : setControlledValue;

  const handleChange = useCallback(
    (_: React.SyntheticEvent, newValue: T) => {
      const previousPanel = authorizedPanels.find((p) => p.key === valueFinal)!;
      const nextPanel = authorizedPanels.find((p) => p.key === newValue)!;

      if (changeTabMiddleware) {
        changeTabMiddleware(() => setValueFinal(newValue), { previousPanel, nextPanel });
      } else {
        setValueFinal(newValue);
      }
    },
    [authorizedPanels, valueFinal, changeTabMiddleware, setValueFinal],
  );

  return (
    <Box
      sx={{
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'visible',
      }}
    >
      <TabContext value={valueFinal}>
        <Box
          sx={{
            mx: -1,
            backgroundColor: '#343c47',
            '& .MuiTabs-indicator': {
              height: '100%',
              backgroundColor: 'background.paper',
              borderTop: '4px solid #eee',
              borderLeft: '1px solid #aaa',
              borderRight: '1px solid #aaa',
              zIndex: 1,
            },
          }}
        >
          <TabList variant="fullWidth" onChange={handleChange} aria-label="Modal tabs">
            {authorizedPanels.map(({ key, label }) => (
              <Tab
                wrapped
                key={key}
                label={
                  <Typography
                    zIndex={2}
                    variant="h6"
                    fontSize="16px !important"
                    textTransform="unset"
                  >
                    {label}
                  </Typography>
                }
                value={key}
                sx={{
                  flexGrow: 1,
                  maxWidth: 'unset',
                  textTransform: 'unset',
                  borderBottom: '1px solid #aaa',
                  '&:not(:last-child)': {
                    borderRight: '1px solid #cccccc22',
                  },
                  '&.Mui-selected': {
                    color: 'white',
                  },
                }}
              />
            ))}
          </TabList>
        </Box>
        {authorizedPanels.length > 0 ? (
          authorizedPanels.map(({ key, component }) => (
            <TabPanel sx={{ p: 0, flexGrow: 1 }} key={key} value={key}>
              {component}
            </TabPanel>
          ))
        ) : (
          <Box height={300} p={4} pb={6} display="grid" alignContent="center" justifyItems="center">
            <BeachAccess sx={{ fontSize: 72 }} />
            <Typography color="text.secondary" fontSize="large">
              <Trans>No tienes acceso a ninguna de las pestañas</Trans>
            </Typography>
          </Box>
        )}
      </TabContext>
    </Box>
  );
};

const Fallback: FC<Pick<Props<string>, 'panels'>> = ({ panels }) => {
  return (
    <Box
      sx={{
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'visible',
      }}
    >
      <TabContext value={0}>
        <Box
          sx={{
            mx: -1,
            backgroundColor: '#343c47',
            '& .MuiTabs-indicator': {
              height: '100%',
              backgroundColor: 'background.paper',
              borderTop: '4px solid #eee',
              borderLeft: '1px solid #aaa',
              borderRight: '1px solid #aaa',
              zIndex: 1,
            },
          }}
        >
          <TabList variant="fullWidth" aria-label="Modal tabs">
            {panels.map(({ key, label }) => (
              <Tab
                wrapped
                disabled
                key={key}
                label={
                  <Skeleton>
                    <Typography
                      zIndex={2}
                      variant="h6"
                      fontSize="16px !important"
                      textTransform="unset"
                    >
                      {label}
                    </Typography>
                  </Skeleton>
                }
                value={key}
                sx={{
                  flexGrow: 1,
                  maxWidth: 'unset',
                  textTransform: 'unset',
                  borderBottom: '1px solid #aaa',
                  '&:not(:last-child)': {
                    borderRight: '1px solid #cccccc22',
                  },
                  '&.Mui-selected': {
                    color: 'white',
                  },
                }}
              />
            ))}
          </TabList>
        </Box>
        <Skeleton variant="rounded" height={360} />
      </TabContext>
    </Box>
  );
};

const ModalTabs = <T extends string = string>(props: Props<T>) => {
  return (
    <Suspense fallback={<Fallback panels={props.panels} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default ModalTabs;
