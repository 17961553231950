import { useGlobalModal } from '@/hooks/useGlobalModal';
import useHasDGAConfiguration from '@/hooks/useHasDGAConfiguration';
import { useIsGate } from '@/hooks/useIsGate';
import useIsRegis from '@/hooks/useIsRegis';
import {
  setDeprecatedCreateMeasurementModal,
  setSaveHardwareConfigurationModal,
  setUpdateDGAConfigurationModal,
  setUpdateDeviceConfigurationModal,
  setUpdateDeviceDataConfigurationModal,
} from '@/slices/modals';
import { Access } from '@features/authorization';
import StandardMenu from '@features/standardDesign/components/StandardMenu';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Settings } from '@mui/icons-material';
import { memo, type ComponentType, type FC, type PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';

interface Props {
  deviceId: string;
  CustomIcon?: ComponentType;
}

const DeviceMenu: FC<Props> = ({ deviceId, CustomIcon }) => {
  const { _ } = useLingui();
  const dispatch = useDispatch();
  const { setModalState: setAlertsModalState } = useGlobalModal('deviceAlerts');

  const openUpdateDeviceConfigurationModal = () => {
    dispatch(
      setUpdateDeviceConfigurationModal({
        open: true,
        deviceId,
      }),
    );
  };

  const openUpdateDeviceDataConfigurationModal = () => {
    dispatch(
      setUpdateDeviceDataConfigurationModal({
        open: true,
        deviceId,
      }),
    );
  };

  const openUpdateDeviceAlertsModal = () => {
    setAlertsModalState({
      open: true,
      deviceId,
    });
  };

  const openCreateMeasurementModal = () => {
    dispatch(
      setDeprecatedCreateMeasurementModal({
        open: true,
        deviceId,
      }),
    );
  };

  const openMeasurementsTableModal = () => {
    dispatch(
      setDeprecatedCreateMeasurementModal({
        open: true,
        deviceId,
      }),
    );
  };

  const openUpdateDGAConfigurationModal = () => {
    dispatch(
      setUpdateDGAConfigurationModal({
        open: true,
        deviceId,
      }),
    );
  };

  const openUpdateDeviceHardwareConfigurationModal = () => {
    dispatch(
      setSaveHardwareConfigurationModal({
        open: true,
        deviceId,
      }),
    );
  };

  return (
    <StandardMenu
      deviceId={deviceId}
      items={[
        {
          id: 1,
          access: Access.DeviceConfigurationModal,
          label: _(msg`Configuración de dispositivo`),
          onClick: openUpdateDeviceConfigurationModal,
        },
        {
          id: 2,
          access: Access.DeviceDataConfigurationModal,
          label: _(msg`Configuración de datos`),
          onClick: openUpdateDeviceDataConfigurationModal,
          Wrapper: ({ children }) => (
            <IsGateWrapper not deviceId={deviceId}>
              {children}
            </IsGateWrapper>
          ),
        },
        {
          id: 7,
          access: Access.SaveHardwareConfigurationModal,
          label: _(msg`Configuración de hardware`),
          onClick: openUpdateDeviceHardwareConfigurationModal,
          Wrapper: ({ children }) => (
            <IsGateWrapper not deviceId={deviceId}>
              {children}
            </IsGateWrapper>
          ),
        },
        {
          id: 3,
          access: Access.DeviceAlertsModal,
          label: _(msg`Configuración de alertas`),
          onClick: openUpdateDeviceAlertsModal,
        },
        {
          id: 4,
          label: _(msg`Ingreso manual de datos`),
          onClick: openCreateMeasurementModal,
          Wrapper: ({ children }) => (
            <IsGateWrapper not deviceId={deviceId}>
              <IsRegisWrapper deviceId={deviceId}>{children}</IsRegisWrapper>
            </IsGateWrapper>
          ),
        },
        {
          id: 5,
          label: _(msg`Ver tabla de datos`),
          onClick: openMeasurementsTableModal,
          Wrapper: ({ children }) => (
            <IsGateWrapper not deviceId={deviceId}>
              <IsRegisWrapper deviceId={deviceId}>{children}</IsRegisWrapper>
            </IsGateWrapper>
          ),
        },
        {
          id: 6,
          label: _(msg`Configuración DGA`),
          onClick: openUpdateDGAConfigurationModal,
          access: Access.UpdateDGAConfigurationsModal,
          Wrapper: ({ children }) => (
            <IsGateWrapper not deviceId={deviceId}>
              <HasDGAConfigurationWrapper deviceId={deviceId}>
                {children}
              </HasDGAConfigurationWrapper>
            </IsGateWrapper>
          ),
        },
      ]}
    >
      {CustomIcon ? <CustomIcon /> : <Settings fontSize="large" />}
    </StandardMenu>
  );
};

interface WrapperProps extends PropsWithChildren<Props> {
  not?: boolean;
}

const IsRegisWrapper: FC<WrapperProps> = memo(({ children, deviceId, not }) => {
  let isRegis = useIsRegis(deviceId);
  if (not) isRegis = !isRegis;
  return isRegis ? children : null;
});

const IsGateWrapper: FC<WrapperProps> = memo(({ children, deviceId, not }) => {
  let isGate = useIsGate(deviceId);
  if (not) isGate = !isGate;
  return isGate ? children : null;
});

const HasDGAConfigurationWrapper: FC<WrapperProps> = memo(({ children, deviceId, not }) => {
  let hasDGAConfiguration = useHasDGAConfiguration(deviceId);
  if (not) hasDGAConfiguration = !hasDGAConfiguration;
  return hasDGAConfiguration ? children : null;
});

export default DeviceMenu;
