import { gql } from '@/__generated__';

export const CREATE_GATE_LINKED_DEVICE = gql(`#graphql
  mutation CreateGateLinkedDevice($input: CreateGateLinkedDeviceInput!) {
    createGateLinkedDevice(input: $input) {
      id
      upstreamDevice {
        id
        profile {
          id
          name
        }
      }
      downstreamDevice {
        id
        profile {
          id
          name
        }
      }
    }
  }
`);
