import ModalContent from '@components/modal/ModalContent';
import { Box, Skeleton } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';
import ModalActions, { type ModalActionsProps } from '@components/modal/ModalActions';

export type TabProps = {
  deviceId: string;
  onClose: () => void;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
};

export interface TabContentProps extends PropsWithChildren {
  slotProps?: {
    modalActions?: ModalActionsProps;
  };
}

export const TabContent: FC<TabContentProps> = ({ children, slotProps }) => {
  return (
    <>
      <ModalContent bottomDivider>
        <Box maxWidth="lg" height="55dvh" overflow="auto" mx="auto" pt={2}>
          {children}
        </Box>
      </ModalContent>
      {slotProps?.modalActions && <ModalActions {...slotProps.modalActions} />}
    </>
  );
};

export const TabFallback: FC<Pick<TabProps, 'onClose'>> = ({ onClose }) => {
  return (
    <TabContent
      slotProps={{
        modalActions: {
          onClose,
        },
      }}
    >
      <Skeleton height="100%" variant="rounded" />
    </TabContent>
  );
};
