import { TextField, type TextFieldProps, type TextFieldVariants } from '@mui/material';
import {
  Controller,
  type ControllerProps,
  type FieldPath,
  type FieldValues,
} from 'react-hook-form';

interface NumericTextFieldControllerProps<
  // textField generics
  TTextFieldVariant extends TextFieldVariants,
  // controller generics
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, 'render'> {
  label?: TextFieldProps<TTextFieldVariant>['label'];
  slotProps?: {
    textField?: Partial<Omit<TextFieldProps<TTextFieldVariant>, 'type' | 'inputMode'>>;
  };
}

export const NumericTextFieldController = <
  TTextFieldVariant extends TextFieldVariants = TextFieldVariants,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: NumericTextFieldControllerProps<TTextFieldVariant, TFieldValues, TName>,
) => {
  const { label, slotProps, ...controllerProps } = props;
  return (
    <Controller
      {...controllerProps}
      render={({ field: { onChange, ...field }, fieldState }) => (
        <TextField
          {...field}
          onChange={onChange}
          value={field.value || field.value === 0 ? String(field.value) : ''}
          label={label}
          error={Boolean(fieldState.error)}
          helperText={fieldState.error?.message}
          inputMode="decimal"
          onBlur={(e) => {
            try {
              const value = parseFloat(e.target.value.replace(',', '.'));
              onChange(isNaN(value) ? undefined : value);
            } catch {
              onChange(undefined);
            }
          }}
          {...slotProps?.textField}
        />
      )}
    />
  );
};
