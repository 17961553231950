import { type Authorization } from '../graphql/queries/getAuthorization';
import { Access } from './accessEnum';

export const validateGeneralAccess = (auth: Authorization, access: Access): boolean => {
  const isValid = (access: Access) => {
    return validateGeneralAccess(auth, access);
  };

  if (access === Access.DeviceAlertsConfigurationModal && auth.authorizationAlert?.adminView) {
    return true;
  }

  if (access === Access.CreateDGAConfigurationModal) {
    if (auth.authorizationDGA?.enableDevices) {
      return true;
    }
  }
  if (access === Access.UpdateDGAConfigurationsModal) {
    return [
      Access.RetryDGASubmissions,
      Access.UpdateDGAGeneralConfiguration,
      Access.DeleteDGAConfiguration,
      Access.UpdateDGASubmissionsConfiguration,
      Access.UpdateDGAMaxFlow,
      Access.SetTicketModal,
      Access.UpdateDGANotificationsConfiguration,
    ].some(isValid);
  }
  if (
    access === Access.UpdateDGAGeneralConfiguration ||
    access === Access.UpdateDGASubmissionsConfiguration ||
    access === Access.DeleteDGAConfiguration
  ) {
    return auth.authorizationDGA.updateInfoDGA;
  }
  if (access === Access.RetryDGASubmissions) return auth.authorizationDGA.retrySending;
  if (access === Access.UpdateDGAMaxFlow) return auth.authorizationDGA.setMaxFlow;
  if (access === Access.UpdateDGANotificationsConfiguration)
    return auth.authorizationDGA.updateNotifications;
  if (access === Access.SetTicketModal) return auth.authorizationDGA.updateTicket;

  if (access === Access.AlertsConfiguration_TableRowAdminView) {
    if (auth.authorizationAlert?.adminView) {
      return true;
    }
  }
  if (access === Access.UsersTable_OptionsMenu) {
    if (auth.authorizationGeneral?.updateUser || auth.authorizationGeneral?.simulateUser) {
      return true;
    }
  }

  if (
    access === Access.UserOptionsMenu_DeleteUserButton ||
    access === Access.SaveUserModal ||
    access === Access.DeleteUserModal
  ) {
    if (auth.authorizationGeneral?.updateUser) {
      return true;
    }
  }

  if (access === Access.CreateTemporalUserModal) {
    if (auth.authorizationGeneral?.temporaryUser) {
      return true;
    }
  }

  if (access === Access.UserOptionsMenu_SimulateUserButton || access === Access.SimulateUserModal) {
    if (auth.authorizationGeneral?.simulateUser) {
      return true;
    }
  }

  if (access === Access.OrganizationsFilter) {
    if (auth.authorizationGeneral?.showOrganizationFilter) {
      return true;
    }
  }

  if (access === Access.SaveHardwareConfigurationModal) {
    return auth.authorizationGeneral.updateDeviceHardwareConfiguration;
  }

  if (access === Access.UpdateDeviceAliasOtherOrganizations) {
    return auth.authorizationGeneral.updateDeviceAliasOtherOrganizations;
  }

  if (access === Access.UpdateDeviceUserAccess) {
    return auth.authorizationGeneral.updateDeviceUserAccess;
  }

  if (access === Access.DeleteDeviceUserAccess) {
    return auth.authorizationGeneral.deleteDeviceUserAccess;
  }

  if (access === Access.CreateDeviceUserAccess) {
    return auth.authorizationGeneral.createDeviceUserAccess;
  }

  if (access === Access.ViewDeviceMetricsPriorityList) {
    return auth.authorizationGeneral.showOrganizationFilter;
  }

  if (access === Access.CreateDevice) {
    return auth.authorizationGeneral.createDevice;
  }

  if (access === Access.ViewDeletedDevices) {
    return auth.authorizationGeneral.createDevice;
  }

  if (access === Access.CreateSMAConfiguration) {
    return auth.authorizationSMA.updateInfoSMA;
  }

  return false;
};
