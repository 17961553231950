import { GateLinkedDeviceDirection } from '@/__generated__/graphql';
import { useModalStatusMessage } from '@/hooks';
import { useMutation } from '@apollo/client';
import ConfirmationModal from '@components/modal/ConfirmationModal';
import { CREATE_GATE_LINKED_DEVICE } from '@features/deviceConfigurationModal/graphql/mutations/createGateLinkedDevice';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { ButtonBase } from '@mui/material';
import { useState, type FC } from 'react';

interface Props {
  gateId: string;
  direction: GateLinkedDeviceDirection;
}

export const CreateLinkedDeviceButton: FC<Props> = ({ gateId, direction }) => {
  const { _ } = useLingui();
  const { openModalSuccessMessage, openModalErrorMessage } = useModalStatusMessage();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [mutate] = useMutation(CREATE_GATE_LINKED_DEVICE, {
    onCompleted() {
      openModalSuccessMessage(_(msg`Se ha creado el dispositivo correctamente`));
      setOpenConfirmation(false);
    },
    onError(error) {
      openModalErrorMessage(_(msg`Error al crear dispositivo: ${error.message}`));
    },
  });

  const modalTitle =
    direction === GateLinkedDeviceDirection.Upstream
      ? _(msg`Crear dispositivo aguas arriba`)
      : _(msg`Crear dispositivo aguas abajo`);

  const onCreateDeviceClick = () => {
    mutate({
      variables: {
        input: {
          gateId,
          direction,
        },
      },
    });
  };

  return (
    <>
      <ConfirmationModal
        title={modalTitle}
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        submitButton={{
          onClick: onCreateDeviceClick,
        }}
      />
      <ButtonBase
        onClick={() => setOpenConfirmation(true)}
        sx={{
          fontFamily: 'inherit',
          fontSize: 'inherit',
          color: ({ palette }) => palette.primary.main,
        }}
      >
        <Trans>Crear dispositivo</Trans>
      </ButtonBase>
    </>
  );
};
