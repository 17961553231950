import { gql } from '@/__generated__';

export const GET_BASIC_ALERT_CONFIGURATION = gql(`#graphql
  query UpdateAlertsModal_GetBasicAlertConfiguration($deviceId: ID!) {
    device(input: { id: $deviceId }) {
      id
      alerts {
        id
        basicConfigurations {
          id
          lastFlow {
            id
            timestamp
            min
            max
            overflow
            unit
            precision
          }
          lastLevel {
            id
            timestamp
            min
            max
            overflow
            unit
            precision
          }
        }
      }
    }
  }
`);
