import { useModalStatusMessage } from '@/hooks';
import { useGlobalModal } from '@/hooks/useGlobalModal';
import { skipToken, useMutation, useSuspenseQuery } from '@apollo/client';
import GlobalModal from '@components/GlobalModal';
import ModalActions from '@components/modal/ModalActions';
import ModalTitle from '@components/modal/ModalTitle';
import DELETE_DEVICE_USER_RELATIONS from '@features/access/graphql/mutations/deleteDeviceUserRelations';
import { Access } from '@features/authorization';
import GET_DEVICES_ACCESS_FOR_USER from '@features/devicesAccessInputs/graphql/queries/getDevicesAccessForUser';
import GET_USERS_ACCESS_FOR_DEVICE from '@features/devicesAccessInputs/graphql/queries/getUserAccessForDevice';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, DialogContent, Skeleton } from '@mui/material';
import { useEffect, type FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FixedSizeList } from 'react-window';
import DevicesListItem from '../devicesTab/DevicesListItem';
import UsersListItem from '../usersTab/UsersListItem';

type FormValues = {
  removeRelations: {
    id: number;
  }[];
};

type Props = {
  onClose: () => void;
} & (
  | {
      fixedDeviceId?: string;
    }
  | {
      fixedUserId?: number;
    }
);

const Component: FC<Props> = (props) => {
  const fixedDeviceId = ('fixedDeviceId' in props && props.fixedDeviceId) || undefined;
  const fixedUserId = ('fixedUserId' in props && props.fixedUserId) || undefined;
  const { _ } = useLingui();
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const { data: devicesDataForUser } = useSuspenseQuery(
    GET_DEVICES_ACCESS_FOR_USER,
    fixedUserId
      ? {
          fetchPolicy: 'no-cache',
          variables: {
            userId: fixedUserId,
            devicesInput: {
              ignoreAccess: true,
            },
          },
        }
      : skipToken,
  );
  const { data: usersDataForDevice } = useSuspenseQuery(
    GET_USERS_ACCESS_FOR_DEVICE,
    fixedDeviceId
      ? {
          fetchPolicy: 'no-cache',
          variables: {
            deviceId: fixedDeviceId,
            devicesUsersInput: {
              ignoreAccess: true,
            },
          },
        }
      : skipToken,
  );
  const [removeRelations] = useMutation(DELETE_DEVICE_USER_RELATIONS, {
    onCompleted: () => {
      openModalSuccessMessage(_(msg`Relaciones eliminadas correctamente`));
      props.onClose();
    },
    onError(error) {
      openModalErrorMessage(error.message);
    },
    refetchQueries: [GET_DEVICES_ACCESS_FOR_USER, GET_USERS_ACCESS_FOR_DEVICE],
    update(cache) {
      cache.modify({
        fields: {
          devices(_, { DELETE }) {
            return DELETE;
          },
          users(_, { DELETE }) {
            return DELETE;
          },
        },
      });
    },
  });

  const { reset, ...methods } = useForm<FormValues>({
    defaultValues: {
      removeRelations: [],
    },
  });

  useEffect(() => {
    reset();
  }, [devicesDataForUser, usersDataForDevice, reset]);

  const onSubmit = methods.handleSubmit((data) => {
    removeRelations({
      variables: {
        inputs: data.removeRelations,
      },
    });
  });

  const relationsForUser = devicesDataForUser?.user.devices;
  const relationsForDevice = usersDataForDevice?.device.users;

  const count = methods.watch('removeRelations').length;

  return (
    <form onSubmit={onSubmit}>
      <ModalTitle title={_(msg`Eliminar relaciones dispositivo-usuario`)} onClose={props.onClose} />
      <DialogContent dividers>
        <Controller
          control={methods.control}
          name="removeRelations"
          render={({ field }) => {
            if (relationsForUser)
              return (
                <Box border={1} borderColor="divider" borderRadius={1}>
                  <FixedSizeList
                    height={500}
                    width="100%"
                    itemSize={56}
                    itemCount={relationsForUser.length}
                    itemKey={(index) => relationsForUser[index].id}
                  >
                    {({ style, index }) => {
                      const relation = relationsForUser[index];
                      const device = relation.device;
                      console.log(field.value);
                      const checked =
                        field.value.find(({ id: relationId }) => relationId === relation.id) !=
                        null;
                      const lastItem = index === relationsForUser.length - 1;
                      const onClick = () => {
                        const newValues = field.value.filter(
                          ({ id: relationId }) => relationId !== relation.id,
                        );
                        field.onChange(checked ? newValues : [...newValues, { id: relation.id }]);
                      };

                      return (
                        <DevicesListItem
                          style={style}
                          key={index}
                          deviceId={device.id}
                          deviceName={device.profile.name}
                          deviceOrganization={device.profile.organization}
                          deviceIrrigationName={device.profile.irrigation?.name}
                          onClick={onClick}
                          hasAccess={checked}
                          lastItem={lastItem}
                          checkboxColor="error"
                        />
                      );
                    }}
                  </FixedSizeList>
                </Box>
              );
            else if (relationsForDevice)
              return (
                <Box border={1} borderColor="divider" borderRadius={1}>
                  <FixedSizeList
                    height={500}
                    width="100%"
                    itemSize={56}
                    itemCount={relationsForDevice.length}
                    itemKey={(index) => relationsForDevice[index].id}
                  >
                    {({ style, index }) => {
                      const relation = relationsForDevice[index];
                      const user = relation.user;
                      const checked =
                        field.value.find(({ id: relationId }) => relationId === relation.id) !=
                        null;
                      const lastItem = index === relationsForDevice.length - 1;
                      const onClick = () => {
                        const newValues = field.value.filter(
                          ({ id: relationId }) => relationId !== relation.id,
                        );
                        field.onChange(checked ? newValues : [...newValues, { id: relation.id }]);
                      };
                      const userOrganization = user.mainOrganization
                        ? {
                            id: user.mainOrganization.organization.id,
                            name: user.mainOrganization.organization.name,
                            roleId: user.mainOrganization.role.id,
                          }
                        : null;

                      return (
                        <UsersListItem
                          key={user.id}
                          style={style}
                          lastItem={lastItem}
                          userId={user.id}
                          userName={user.fullName}
                          userEmail={user.email}
                          userOrganization={userOrganization}
                          onClick={onClick}
                          hasAccess={checked}
                          checkboxColor="error"
                        />
                      );
                    }}
                  </FixedSizeList>
                </Box>
              );
            else return <Box />;
          }}
        />
      </DialogContent>
      <ModalActions
        dirtyForm={methods.formState.isDirty}
        submitButton={{
          color: 'error',
          type: 'submit',
          label: _(msg`Eliminar ${count} registros`),
        }}
        onClose={props.onClose}
      />
    </form>
  );
};

const Fallback: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { _ } = useLingui();
  return (
    <>
      <ModalTitle title={_(msg`Eliminar relaciones dispositivo-usuario`)} onClose={onClose} />
      <DialogContent dividers>
        <Skeleton height={400} variant="rounded" />
      </DialogContent>
      <ModalActions onClose={onClose} />
    </>
  );
};

const RemoveDeviceUserRelationsModal: FC = () => {
  const { globalModalProps, state, onClose } = useGlobalModal('removeDeviceUserRelationsModal');

  return (
    <GlobalModal
      {...globalModalProps}
      dialogProps={{ maxWidth: 'md' }}
      authorization={{
        access: Access.DeleteDeviceUserAccess,
        onClose,
      }}
      suspenseFallback={<Fallback onClose={onClose} />}
    >
      {state.open && (
        <>
          {'fixedUserId' in state ? (
            <Component fixedUserId={state.fixedUserId} onClose={onClose} />
          ) : 'fixedDeviceId' in state ? (
            <Component fixedDeviceId={state.fixedDeviceId} onClose={onClose} />
          ) : (
            <Component onClose={onClose} />
          )}
        </>
      )}
    </GlobalModal>
  );
};

export default RemoveDeviceUserRelationsModal;
