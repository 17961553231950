import { Authorization } from '../graphql/queries/getAuthorization';
import { DeviceAuthorization } from '../graphql/queries/getDeviceAuthorization';
import { Access } from './accessEnum';
import { validateDeviceAuthorization } from './validateDeviceAccess';
import { validateGeneralAccess } from './validateGeneralAccess';

export const validateAccess = (
  auth: Authorization,
  deviceAuth: DeviceAuthorization | undefined,
  access: Access,
  deviceId?: string,
): boolean => {
  if (access === Access._Test_True) return true;
  if (access === Access._Test_False) return false;
  if (access === Access._Test_Random) return Math.random() > 0.5;

  if (deviceId && deviceAuth) {
    return (
      validateDeviceAuthorization(deviceAuth, access, deviceId) ||
      validateGeneralAccess(auth, access)
    );
  } else return validateGeneralAccess(auth, access);
};
