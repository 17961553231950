import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableCellProps,
  TableProps,
  TableContainerProps,
} from '@mui/material';
import { type FC, ReactNode, Fragment } from 'react';

export type StandardTableHeaderCell =
  | {
      id: string | number;
      value: ReactNode;
      cellProps?: TableCellProps;
    }
  | ReactNode;

export type StandardTableRowCell =
  | {
      id: string | number;
      value: ReactNode;
      cellProps?: TableCellProps;
    }
  | ReactNode;

export type StandardTableRow = {
  id: string | number;
  cells: StandardTableRowCell[];
  bottomDecoration?: ReactNode;
};

interface StandardTableProps {
  headers: StandardTableHeaderCell[];
  rows: StandardTableRow[];
  slotProps?: {
    table?: TableProps;
    tableContainer?: TableContainerProps;
  };
}

const StandardTable: FC<StandardTableProps> = ({ headers, rows, slotProps }) => {
  return (
    <TableContainer component={Paper} {...slotProps?.tableContainer}>
      <Table {...slotProps?.table} sx={{ minWidth: 650, ...slotProps?.table?.sx }}>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => {
              if (
                typeof header === 'object' &&
                header != null &&
                !('type' in header) &&
                'id' in header &&
                'value' in header
              ) {
                return (
                  <TableCell key={header.id} component="th" {...header.cellProps}>
                    {header.value}
                  </TableCell>
                );
              }
              return (
                <TableCell key={index} component="th">
                  {header}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '.MuiTableRow-root:last-child td, .MuiTableRow-root:last-child th': { border: 0 },
          }}
        >
          {rows.map((row) => (
            <Fragment key={row.id}>
              <TableRow>
                {row.cells.map((cell, index) => {
                  if (
                    typeof cell === 'object' &&
                    cell != null &&
                    !('type' in cell) &&
                    'id' in cell &&
                    'value' in cell
                  ) {
                    return (
                      <TableCell
                        key={cell.id}
                        scope="row"
                        {...cell.cellProps}
                        sx={{
                          ...(row.bottomDecoration ? { border: 0 } : {}),
                          ...cell.cellProps?.sx,
                        }}
                      >
                        {cell.value}
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell
                      key={index}
                      scope="row"
                      sx={{
                        ...(row.bottomDecoration ? { border: 0 } : {}),
                      }}
                    >
                      {cell}
                    </TableCell>
                  );
                })}
              </TableRow>
              {row.bottomDecoration && (
                <TableRow sx={{}}>
                  <TableCell colSpan={headers.length} padding="none">
                    {row.bottomDecoration}
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StandardTable;
