import { gql } from '@/__generated__';

export const GET_USERS_FOR_DEVICE = gql(`#graphql
  query UpdateAlertsModal_GetUsersForDevice($deviceId: ID!) {
    device(input: { id: $deviceId }) {
      id
      users {
        id
        user {
          id
          fullName
          email
          phone
        }
        alertNotificationConfiguration {
          id
          notifyEmail
          notifyPush
          notifySms
          notifyWhatsapp
        }
      }
    }
  }
`);
