import { DeviceAlertMetricName } from '@/__generated__/graphql';
import { AutocompleteController } from '@components/controllers/AutocompleteController';
import { NumericTextFieldController } from '@components/controllers/NumericTextFieldController';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, InputAdornment } from '@mui/material';
import { useEffect, type FC } from 'react';
import { TabContent, type TabProps } from '../common';
import { useUpdateForm } from './useUpdateForm';

export const FormComponent: FC<TabProps> = ({ deviceId, onClose, setIsDirty }) => {
  const { _ } = useLingui();

  const { methods, onSubmit } = useUpdateForm(deviceId);

  useEffect(() => {
    setIsDirty(methods.formState.isDirty);
  }, [methods.formState.isDirty, setIsDirty]);

  const alertType = methods.watch('alertType');

  useEffect(() => {
    const defaultValues = methods.formState.defaultValues;
    if (defaultValues?.alertType === alertType) {
      methods.reset();
    } else {
      methods.setValue('min', undefined!);
      methods.setValue('max', undefined!);
      methods.setValue('overflow', undefined!);
    }
  }, [alertType, methods]);

  const unit = alertType === DeviceAlertMetricName.Flow ? 'L/s' : 'cm';

  return (
    <form onSubmit={onSubmit}>
      <TabContent
        slotProps={{
          modalActions: {
            onClose,
            onSubmit: 'submit',
            dirtyForm: methods.formState.isDirty,
            submitLoading: methods.formState.isSubmitting,
            onResetForm: () => methods.reset(),
          },
        }}
      >
        <Box display="flex" flexDirection="column" gap={2} maxWidth="sm" mx="auto">
          <AutocompleteController
            control={methods.control}
            name="alertType"
            options={[DeviceAlertMetricName.Flow, DeviceAlertMetricName.Level]}
            label={_(msg`Tipo de alerta`)}
            slotProps={{
              autocomplete: {
                disableClearable: true,
                getOptionLabel(option) {
                  return option === DeviceAlertMetricName.Level ? _(msg`Nivel`) : _(msg`Caudal`);
                },
              },
            }}
          />
          <NumericTextFieldController
            control={methods.control}
            name="min"
            label={_(msg`Mínimo`)}
            slotProps={{
              textField: {
                InputProps: {
                  endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
                },
              },
            }}
          />
          <NumericTextFieldController
            control={methods.control}
            name="max"
            label={_(msg`Máximo`)}
            slotProps={{
              textField: {
                InputProps: {
                  endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
                },
              },
            }}
          />
          <NumericTextFieldController
            control={methods.control}
            name="overflow"
            label={_(msg`Desborde`)}
            slotProps={{
              textField: {
                InputProps: {
                  endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
                },
              },
            }}
          />
        </Box>
      </TabContent>
    </form>
  );
};
