import { useModalStatusMessage } from '@/hooks';
import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useForm } from 'react-hook-form';
import { UPDATE_ALERT_NOTIFICATION_CONFIGURATIONS } from './updateMutation';
import { FormValues, UpdateAlertNotificationConfigurationsSchema } from './updateSchema';

export const useUpdateForm = () => {
  const { _ } = useLingui();
  const { openModalSuccessMessage, openModalErrorMessage } = useModalStatusMessage();
  const methods = useForm<FormValues>({
    defaultValues: {
      input: [],
    },
    resolver: zodResolver(UpdateAlertNotificationConfigurationsSchema),
  });

  const [mutate] = useMutation(UPDATE_ALERT_NOTIFICATION_CONFIGURATIONS, {
    onCompleted() {
      openModalSuccessMessage(_(msg`Notificaciones actualizadas correctamente`));
      methods.reset();
    },
    onError(error) {
      openModalErrorMessage(_(msg`Error al actualizar las notificaciones: ${error.message}`));
    },
  });

  const onSubmit = methods.handleSubmit(({ input }) => {
    mutate({ variables: { input } });
  });

  return {
    methods,
    onSubmit,
  };
};
