import { z } from 'zod';

const UpdateAlertNotificationConfigurationInputSchema = z.object({
  deviceId: z.string(),
  userId: z.number().int(),
  notifyEmail: z.boolean(),
  notifySms: z.boolean(),
  notifyWhatsapp: z.boolean(),
  notifyPush: z.boolean(),
});

export const UpdateAlertNotificationConfigurationsSchema = z.object({
  input: z.array(UpdateAlertNotificationConfigurationInputSchema),
});

export type FormValues = z.infer<typeof UpdateAlertNotificationConfigurationsSchema>;
