import { useModalStatusMessage } from '@/hooks';
import { useMutation } from '@apollo/client';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { FC, useEffect, useState } from 'react';
import CreateNewUser from './CreateNewUser';
import ListUserAlarm from './ListUsersAlarm';
import { UPDATE_GATE_ALARM } from './updateGateAlarm';

const initialUser = {
  name: '',
  phone: '',
  notification: true,
  type: 'created',
  index: 0,
};

interface Props {
  gateId: string;
  gateAlarmData: string | null;
  open: boolean;
  onClose: () => void;
}

const UpdateAlarm: FC<Props> = ({ gateId, gateAlarmData, open, onClose }) => {
  const { _ } = useLingui();
  const { openModalSuccessMessage, openModalErrorMessage } = useModalStatusMessage();
  const [mutate] = useMutation(UPDATE_GATE_ALARM, {
    onCompleted({ updateGate: { alarmData } }) {
      openModalSuccessMessage(_(msg`Usuarios a notificar actualizados correctamente`));
      setUsers(JSON.parse(alarmData));
    },
    onError(error) {
      openModalErrorMessage(_(msg`Hubo un error al actualizar: ${error.message}`));
    },
  });

  let initialUsersData = [];
  try {
    if (gateAlarmData && Array.isArray(JSON.parse(gateAlarmData)))
      initialUsersData = JSON.parse(gateAlarmData);
  } catch {
    /* do nothing */
  }

  const [users, setUsers] = useState(initialUsersData);
  const [showAddNewUser, setShowAddNewUser] = useState(false);
  const [values, setValues] = useState(initialUser);
  const [errors, setErrors] = useState({});
  const [isAllSelected, setIsAllSelected] = useState(true);
  const [searchUserValue, setSearchUserValue] = useState('');

  // validation
  const validate = ({ fieldValues = values }: any) => {
    const temp: any = { ...errors };
    const regexPhone = /^\+?\d{7,20}$/;

    if (!fieldValues.name.trim()) {
      temp.name = 'El nombre es requerido.';
    } else {
      temp.name = '';
    }
    if (!fieldValues.phone.trim()) {
      temp.phone = 'El número de telefono es requerido.';
    } else if (!regexPhone.test(fieldValues.phone.trim())) {
      temp.phone = 'El número es incorrecto.';
    } else {
      temp.phone = '';
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
    return false;
  };

  // filter users
  useEffect(() => {
    let searchUser = gateAlarmData;
    if (searchUser && typeof searchUser === 'string') {
      searchUser = JSON.parse(searchUser);
    }
    if (searchUser && searchUserValue !== '') {
      setUsers(
        searchUser.filter(({ name }) => name.toLowerCase().includes(searchUserValue.toLowerCase())),
      );
    } else if (searchUser) {
      setUsers(searchUser);
    }
  }, [searchUserValue, gateAlarmData]);

  const handleSearchUserValueChange = (event: React.ChangeEvent<{ value: string }>) => {
    const { value } = event.target;
    setSearchUserValue(value);
  };

  // new user
  const handleSaveNewUser = async () => {
    const validateFrom = validate({ ...values });
    if (!validateFrom) return;
    setShowAddNewUser(false);
    let alarmData: any = '';
    const { phone } = values;
    let phoneV = '';
    if (!phone.includes('+')) {
      phoneV = `+${phone}`;
    } else {
      phoneV = phone;
    }

    if (users && users.length) {
      alarmData = [...users, { name: values.name, phone: phoneV, notification: true }];
      alarmData = JSON.stringify(alarmData);
    } else {
      alarmData = JSON.stringify([{ name: values.name, phone: phoneV, notification: true }]);
    }

    await mutate({
      variables: {
        gateId,
        alarmData,
      },
    });
  };

  // select
  const handleSelectAll = async () => {
    if (users) {
      const usersState = users?.map((t) => {
        return {
          name: t.name,
          phone: t.phone,
          notification: !isAllSelected,
        };
      });
      setIsAllSelected(!isAllSelected);
      await mutate({
        mutation: UPDATE_GATE_ALARM,
        variables: {
          gateId,
          alarmData: JSON.stringify(usersState),
        },
      });
    }
  };

  // checkout notification
  const hanldecheckedUsers = async (phone: string) => {
    if (users && users.length) {
      const usersState = users?.map((t: any) => {
        return {
          name: t.name,
          phone: t.phone,
          notification: t.phone === phone ? !t.notification : t.notification,
        };
      });
      setUsers(usersState);
      await mutate({
        variables: {
          gateId,
          alarmData: JSON.stringify(usersState),
        },
      });
    }
  };

  // delate users
  const handleDeleteUser = async (phone: string) => {
    const usersState = users?.filter((t) => t.phone !== phone);
    setUsers(usersState);
    await mutate({
      variables: {
        gateId,
        alarmData: JSON.stringify(usersState),
      },
    });
  };

  const handleUpdatedTypeUser = async (user: any) => {
    const index = users.indexOf(user);
    setValues({
      ...user,
      type: 'updated',
      index: index,
    });
    setShowAddNewUser(true);
  };

  const handleUpdatedUser = async (user: any) => {
    const usersUpdated = users;
    usersUpdated.splice(values.index, 1, {
      name: values.name,
      phone: values.phone,
      notification: values.notification,
    });
    await mutate({
      variables: {
        gateId,
        alarmData: JSON.stringify(usersUpdated),
      },
    });
    setValues(initialUser);
    setShowAddNewUser(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone' && value.trim()) {
      const regn = value.replace(' ', '');
      setValues({
        ...values,
        [name]: regn,
      });
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const handleCloseNewUser = () => {
    setShowAddNewUser(false);
    setValues(initialUser);
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth onClose={onClose}>
      <Box display="flex" flexDirection="column" width="100%" height="100%">
        <Box display="flex">
          <Box display="flex" flexDirection="column" alignItems="flex-end" p={2}>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box mb={2}>
                <Typography variant="h6">Usuarios a notificar alarma en {gateId}</Typography>
              </Box>
              <Grid container display="flex" flexDirection="row" spacing={1}>
                <Grid item xs={12}>
                  <Box>
                    <TextField
                      fullWidth
                      label="Buscar Usuario"
                      value={searchUserValue}
                      onChange={handleSearchUserValueChange}
                    />
                  </Box>
                </Grid>
                {users && users.length ? (
                  <Grid item xs={12}>
                    {isAllSelected ? (
                      <Button startIcon={<RemoveIcon />} onClick={handleSelectAll}>
                        Deseleccionar todos los usuarios
                      </Button>
                    ) : (
                      <Button startIcon={<CheckIcon />} onClick={handleSelectAll}>
                        Seleccionar todos los usuarios
                      </Button>
                    )}
                  </Grid>
                ) : (
                  <></>
                )}
                {!users || !users.length ? (
                  <Grid
                    item
                    xs={12}
                    p={2}
                    height={250}
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      sx={{
                        mb: 2,
                      }}
                    >
                      No hay usuarios para notificar alarma...
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <ListUserAlarm
                      users={users}
                      hanldecheckedUsers={hanldecheckedUsers}
                      handleDeleteUser={handleDeleteUser}
                      handleUpdatedTypeUser={handleUpdatedTypeUser}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box my={2}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            sx={{
              gap: '20px',
            }}
          >
            <Box>
              <Button sx={{ ml: 2 }} variant="outlined" onClick={onClose}>
                Cerrar
              </Button>
            </Box>
            <Button variant="contained" onClick={() => setShowAddNewUser(true)}>
              Agregar nuevo usuario
            </Button>
          </Box>
        </Box>
      </Box>
      <CreateNewUser
        show={showAddNewUser}
        handleClose={handleCloseNewUser}
        handleSave={handleSaveNewUser}
        handleUpdatedUser={handleUpdatedUser}
        handleInputChange={handleInputChange}
        values={values}
        errors={errors}
      />
    </Dialog>
  );
};

export default UpdateAlarm;
