import { gql } from '@/__generated__';

export const GET_GATE_TAB_DATA = gql(`#graphql
  query DeviceConfiguration_GetGateTabData($gateId: ID!) {
    gate(input: { id: $gateId }) {
      id
      alarmData
      hasAlarm
      dataConfiguration {
        maxPosition
        minPosition
        calibrationDownstream
        calibrationEncoder
        calibrationUpstream
      }
      upstreamDevice {
        id
        profile {
          id
          name
        }
      }
      downstreamDevice {
        id
        profile {
          id
          name
        }
      }
    }
  }
`);
