import { Device } from '@/types/device';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface StationsState {
  devices: Device[];
  devicesLoading: boolean;
  selectedStation: Device | null;
  selectedType: string;
  lat: number;
  lng: number;
  showUpdateHistory: boolean;
  showPerfil: boolean;
  showAccount: boolean;
  selectedAccount: any | null;
  showChangeSupportStatus: boolean;
  showChangeTags: boolean;
  showHistoryDevice: boolean;
}

const initialState: StationsState = {
  devices: [],
  devicesLoading: false,
  selectedStation: null,
  selectedType: 'all',
  showUpdateHistory: false,
  lat: 0,
  lng: 0,
  showPerfil: false,
  showAccount: false,
  selectedAccount: null,
  showChangeSupportStatus: false,
  showChangeTags: false,
  showHistoryDevice: false,
};

const { reducer, actions } = createSlice({
  name: 'stations_list_store',
  initialState,
  reducers: {
    resetStore: () => {
      return initialState;
    },
    setShowPerfil(state: StationsState, action: PayloadAction<any>): void {
      state.showPerfil = action.payload;
    },
    setShowAccount(state: StationsState, action: PayloadAction<any>): void {
      state.showAccount = action.payload;
    },
    setSelectedAccount(state: StationsState, action: PayloadAction<any>): void {
      state.selectedAccount = action.payload;
    },
    setShowChangeSupportStatus(state: StationsState, action: PayloadAction<boolean>): void {
      state.showChangeSupportStatus = action.payload;
    },
    setShowChangeTags(state: StationsState, action: PayloadAction<boolean>): void {
      state.showChangeTags = action.payload;
    },
  },
});

export default reducer;

export const {
  resetStore: resetStationsStore,
  setShowPerfil,
  setShowAccount,
  setSelectedAccount,
  setShowChangeSupportStatus,
  setShowChangeTags,
} = actions;
