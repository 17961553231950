import { useModalStatusMessage } from '@/hooks';
import { useGlobalModal } from '@/hooks/useGlobalModal';
import { useMutation } from '@apollo/client';
import GlobalModal from '@components/GlobalModal';
import DeviceTooltip from '@components/tooltips/DeviceTooltip';
import { Access } from '@features/authorization';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Typography } from '@mui/material';
import { type FC } from 'react';
import { DISABLE_DEVICE_ALERTS } from './disableDeviceAlerts';

export const DisableAlertsModal: FC = () => {
  const { _ } = useLingui();
  const { globalModalProps, state, onClose } = useGlobalModal('disableDeviceAlertsConfiguration');
  const { openModalSuccessMessage, openModalErrorMessage } = useModalStatusMessage();
  const [mutate, { loading }] = useMutation(DISABLE_DEVICE_ALERTS, {
    onCompleted() {
      openModalSuccessMessage(_(msg`Alertas desactivadas correctamente`));
      onClose();
    },
    onError(error) {
      openModalErrorMessage(_(msg`Hubo un error al desactivar las alertas: ${error.message}`));
    },
  });

  return (
    <GlobalModal
      {...globalModalProps}
      authorization={{
        access: Access.DisableDeviceAlertsConfiguration,
        deviceId: state.open ? state.deviceId : undefined,
        onClose,
      }}
      dialogProps={{
        maxWidth: 'sm',
        fullWidth: true,
      }}
      modalTitleProps={{
        title: <Trans>Desactivar alertas</Trans>,
        onClose,
      }}
      modalActionProps={{
        onSubmit: 'submit',
        onClose,
        submitColor: 'error',
        submitLabel: _(msg`Desactivar`),
        submitLoading: loading,
      }}
      modalContentProps={{}}
      formProps={{
        onSubmit(event) {
          event.preventDefault();
          event.stopPropagation();
          if (state.open)
            mutate({
              variables: {
                deviceId: state.deviceId,
              },
            });
        },
      }}
    >
      {state.open && (
        <Trans>
          ¿Estás seguro que deseas desactivar las alertas para el dispositivo con id{' '}
          <DeviceTooltip deviceId={state.deviceId} placement="top">
            <Typography component="b" noWrap fontWeight="bold">
              {state.deviceId}
            </Typography>
          </DeviceTooltip>
          ?
        </Trans>
      )}
    </GlobalModal>
  );
};
