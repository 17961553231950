import {
  Autocomplete,
  Box,
  type BoxProps,
  Pagination,
  type PaginationProps,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { type FC } from 'react';

export interface StandardListPaginationProps extends BoxProps {
  totalItems: number;
  page: number;
  onPageChange: (page: number) => void;
  rowsPerPage: number;
  onRowsPerPageChange?: (rowsPerPage: number) => void;
  slotProps?: {
    root?: BoxProps;
    pagination?: PaginationProps;
  };
}

const StandardListPagination: FC<StandardListPaginationProps> = ({
  totalItems,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  slotProps,
  ...boxProps
}) => {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Box
      display="flex"
      justifyContent="end"
      alignItems="center"
      gap={2}
      {...boxProps}
      {...slotProps?.root}
    >
      {onRowsPerPageChange && (
        <Autocomplete
          size="small"
          options={[5, 10, 15, 20, 30, 50]}
          value={rowsPerPage}
          disableClearable
          getOptionLabel={(option) => option.toString()}
          renderInput={(params) => <TextField {...params} label="Filas" />}
          onChange={(_, value) => {
            onPageChange(1);
            onRowsPerPageChange(value);
          }}
        />
      )}
      <Pagination
        size={upMd ? 'large' : 'small'}
        variant="text"
        showFirstButton={upMd}
        showLastButton={upMd}
        siblingCount={upMd ? 1 : 0}
        count={Math.ceil(totalItems / rowsPerPage)}
        page={page}
        onChange={(_, page) => onPageChange(page)}
        {...slotProps?.pagination}
      />
    </Box>
  );
};

export default StandardListPagination;
