// TODO: improve order structure and standarize naming
export enum Access {
  // test
  _Test_True,
  _Test_False,
  _Test_Random,

  // components
  OrganizationsFilter,
  AlertsConfiguration_TableRowAdminView,

  // modals
  DeviceAlertsConfigurationModal,
  SaveUserModal,
  SimulateUserModal,
  DeleteUserModal,
  CreateTemporalUserModal,
  SaveHardwareConfigurationModal,
  SetTicketModal,
  UpdateDeviceAliasOtherOrganizations,

  // DGA
  CreateDGAConfigurationModal,
  UpdateDGAConfigurationsModal,
  UpdateDGAGeneralConfiguration,
  UpdateDGASubmissionsConfiguration,
  RetryDGASubmissions,
  UpdateDGAMaxFlow,
  UpdateDGANotificationsConfiguration,
  DeleteDGAConfiguration,

  // buttons
  UsersTable_OptionsMenu,
  UserOptionsMenu_SimulateUserButton,
  UserOptionsMenu_DeleteUserButton,
  UpdateRatingCurve,

  // actions
  UpdateDeviceUserAccess,
  CreateDeviceUserAccess,
  DeleteDeviceUserAccess,
  CreateDevice,
  CreateSMAConfiguration,
  ViewDeletedDevices,
  UpdateDeviceStatus,
  ViewDeviceMetricsPriorityList,

  // Device configuration
  /**
   * Access to enter to device configuration modal,
   * the access is enabled if any of the following is true:
   * - Has access to DeviceConfiguration_GeneralTab
   * - Has access to DeviceConfiguration_StateTab
   * - Has access to DeviceConfiguration_AccessTab
   * - Has access to CloneDevice
   * - Has access to DeleteDevice
   */
  DeviceConfigurationModal,
  /**
   * Access to enter to general tab from device configuration modal,
   * the access is enabled if any of the following is true:
   * - Has access to UpdateDeviceBasicConfiguration
   * - Has access to UpdateDeviceAlias
   */
  DeviceConfiguration_GeneralTab,
  DeviceConfiguration_GateTab,
  /**
   * Access to enter to state tab from device configuration modal,
   * the access is enabled if any of the following is true:
   * - Has access to UpdateDeviceOperationalState
   */
  DeviceConfiguration_StateTab,
  /**
   * Access to enter to access tab from device configuration modal,
   * the access is enabled if any of the following is true:
   * - Has access to UpdateDevice_DeviceUserAccess
   * - Has access to UpdateDevice_AlertNotificationsConfiguration
   */
  DeviceConfiguration_AccessTab,
  CloneDevice,
  DeleteDevice,
  /**
   * Access to update the following attributes from device:
   * - name
   * - type
   * - organization
   * - icn
   * - location
   */
  UpdateDeviceBasicConfiguration,
  UpdateDeviceAlias,
  UpdateDevice_DeviceUserAccess,

  /**
   * Access to enter to alerts configuration modal for a device,
   * the access is enabled if any of the following is true:
   * - Has access to UpdateDeviceAlertNotificationsConfiguration
   * - Has access to UpdateDeviceAlertsConfiguration
   * - Has access to UpdateDeviceAlertsBasicConfiguration
   * - Has access to DisableDeviceAlertsConfiguration
   */
  DeviceAlertsModal,
  UpdateDeviceAlertNotificationsConfiguration,
  UpdateDeviceAlertsConfiguration,
  UpdateDeviceAlertsBasicConfiguration,
  DisableDeviceAlertsConfiguration,
  ShowRawDeviceAlertsData,
  /**
   * Access to update device operational state,
   * the access if enabled if any of the specific
   * update operationalState action access is enabled
   */
  UpdateDeviceOperationalState,
  UpdateDeviceOperationalState_ProductionToInitialization,
  UpdateDeviceOperationalState_ProductionToOutOfService,
  UpdateDeviceOperationalState_InitializationToActive,
  UpdateDeviceOperationalState_InitializationToOutOfService,
  UpdateDeviceOperationalState_ActiveToMaintenance,
  UpdateDeviceOperationalState_ActiveToOutOfService,
  UpdateDeviceOperationalState_MaintenanceToActive,
  UpdateDeviceOperationalState_MaintenanceToOutOfService,

  // Device data configuration
  DeviceDataConfigurationModal,
  DeviceDataConfiguration_CalibrationTab,
  DeviceDataConfiguration_FlowLimitTab,
  DeviceDataConfiguration_FiltersTab,
  DeviceDataConfiguration_RatingCurvesTab,
  DeviceDataConfiguration_MathModelingTab,
  DeviceDataConfiguration_RadarTab,
}
